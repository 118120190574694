import React, { useState, useEffect } from "react";

import certi from "../../assets/subnav/stock cert white.svg";
import token from "../../assets/subnav/Stock token bnw 3.svg";
import wallstreet from "../../assets/subnav/wall 4 1.svg";
import etf from "../../assets/subnav/etf white 1.svg";
import buy from "../../assets/subnav/buy 4 1.svg";
import learn_more from "../../assets/subnav/learn_more.svg";

import certi_light from "../../assets/subnav/light-icons/stock logo HD BnW.svg";
import token_light from "../../assets/subnav/light-icons/Stock token bnw.svg";
import wallstreet_light from "../../assets/subnav/light-icons/wall street icon 3 1.svg";
import etf_light from "../../assets/subnav/light-icons/etf logo 3.svg";
import buy_light from "../../assets/subnav/buy 4 1.svg";
import learn_more_light from "../../assets/subnav/learn_more.svg";

// import convert from "../../assets/subnav/sampaw 2.svg";
// import shop_certi from "../../assets/subnav/shop logo 4.svg";
// import shop_stock from "../../assets/subnav/shop stock.svg";
// import xrock from "../../assets/subnav/xrock white.svg";

import "./SubHeader.css";
import { Link, useLocation } from "react-router-dom";
// import { Button } from '@mui/material';
// import { HashLink } from 'react-router-hash-link';
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

const SubHeader = () => {
  const location = useLocation();
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("md"));
  const isMobile2 = useMediaQuery(themes.breakpoints.down("sm"));

  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: `${isMobile ? "60px" : "70px"}`,
        width: "100%",
        zIndex: 999,
        background: "var(--background)",
        // height: `${isMobile ? "72px" : "80px"}`,
        height: "80px",
      }}
    >
      {/* <div className="container cover-page"> */}
      <div
        // className="row row-cols-1 row-cols-md-4 g-4 up-logos container"
        className="up-logos cover-page"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: isMobile2 ? "100%" : "50%",
          margin: "auto",
          //
          overflowX: isMobile2 ? "auto" : "hidden",
          overflowY: "hidden",
        }}
      >
        <div className="col" style={{ marginLeft: isMobile2 ? "50%" : "0" }}>
          <Link to="/">
            <div className="card">
              <img
                src={theme === "dark" ? wallstreet : wallstreet_light}
                className="card-img-top"
                alt="..."
                style={{
                  width: "70px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title">Wall Street</h5>
                <Typography
                  component="p"
                  sx={
                    location.pathname === "/"
                      ? {
                          height: "0.5px",
                          width: "52px",
                          backgroundColor: "var(--color-text)",
                        }
                      : null
                  }
                ></Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="col">
          <Link to="/certificates?type=all">
            <div className="card">
              <img
                src={theme === "dark" ? certi : certi_light}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Stock Certificate</h5>
                <Typography
                  component="p"
                  sx={
                    location.pathname === "/certificates" ||
                    location.pathname.startsWith("/view-certificate/") ||
                    location.pathname === "/learn-certificates"
                      ? {
                          height: "0.5px",
                          width: "82px",
                          backgroundColor: "var(--color-text)",
                        }
                      : null
                  }
                ></Typography>
              </div>
            </div>
          </Link>
        </div>
        {(location.pathname === "/certificates" ||
          location.pathname === "/learn-certificates") && (
          <>
            <div className="col">
              <Link to="/certificates?type=all">
                <div className="card">
                  <img
                    src={theme === "dark" ? buy : buy_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Buy
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/certificates"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/learn-certificates">
                <div className="card">
                  <img
                    src={theme === "dark" ? learn_more : learn_more_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Learn More
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/learn-certificates"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
          </>
        )}
        <div className="col">
          <Link to="/details?type=all">
            <div className="card">
              <img
                src={theme === "dark" ? token : token_light}
                className="card-img-top"
                width={"38px"}
                alt="..."
                style={{
                  width: "45px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title">Stock Token</h5>
                <Typography
                  component="p"
                  sx={
                    location.pathname === "/details" ||
                    location.pathname.startsWith("/view-detail/") ||
                    location.pathname === "/learn-tokens"
                      ? {
                          height: "0.5px",
                          width: "59px",
                          backgroundColor: "var(--color-text)",
                        }
                      : null
                  }
                ></Typography>
              </div>
            </div>
          </Link>
        </div>
        {(location.pathname === "/details" ||
          location.pathname === "/learn-tokens") && (
          <>
            <div className="col">
              <Link to="/details">
                <div className="card">
                  <img
                    src={theme === "dark" ? buy : buy_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Buy
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/details"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/learn-tokens">
                <div className="card">
                  <img
                    src={theme === "dark" ? learn_more : learn_more_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Learn More
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/learn-tokens"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
          </>
        )}
        <div className="col">
          <Link to="/etf">
            <div className="card">
              <img
                src={theme === "dark" ? etf : etf_light}
                className="card-img-top"
                width={"38px"}
                alt="..."
                style={{
                  width: "45px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title">ETF</h5>
                <Typography
                  component="p"
                  sx={
                    location.pathname === "/etf" ||
                    location.pathname.startsWith("/view-etf/") ||
                    location.pathname === "/learn-etfs"
                      ? {
                          height: "0.5px",
                          width: "59px",
                          backgroundColor: "var(--color-text)",
                        }
                      : null
                  }
                ></Typography>
              </div>
            </div>
          </Link>
        </div>
        {(location.pathname === "/etf" ||
          location.pathname === "/learn-etfs") && (
          <>
            <div className="col">
              <Link to="/etf">
                <div className="card">
                  <img
                    src={theme === "dark" ? buy : buy_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Buy
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/etf"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link to="/learn-etfs">
                <div className="card">
                  <img
                    src={theme === "dark" ? learn_more : learn_more_light}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#595959" }}>
                      Learn More
                    </h5>
                    <Typography
                      component="p"
                      sx={
                        location.pathname === "/learn-etfs"
                          ? {
                              height: "0.5px",
                              width: "82px",
                              backgroundColor: "#595959",
                            }
                          : null
                      }
                    ></Typography>
                  </div>
                </div>
              </Link>
            </div>
          </>
        )}
        {/* 
        <div className="col">
        <HashLink to="https://shop.indexx.ai/collections/indexx-stock-token-tickets">
            <div className="card">
            <img src={shop_certi} className="card-img-top" alt="..."/>
            <div className="card-body">
                <h5 className="card-title">Shop Stock <br/>
Certificate</h5>
            </div>
            </div>
        </HashLink>
        </div>
        
        <div className="col">
        <a href="https://shop.indexx.ai/collections/stock-gift-cards">
            <div className="card">
            <img src={shop_stock} className="card-img-top" alt="..." width={"50px"} height={"41px"}/>
            <div className="card-body">
                <h5 className="card-title">Shop Stock <br/>
Token</h5>
            </div>
            </div>
        </a>
        </div>
        
        <div className="col">
        <a href="/coming-soon?page=XRock">
            <div className="card">
            <img src={xrock} className="card-img-top" alt="..."/>
            <div className="card-body">
                <h5 className="card-title">XRock</h5>
            </div>
            </div>
        </a>
        </div>
        <div className="col">
        <a href="/coming-soon?page=Stock%20Certificate%20to%20Token">
            <div className="card">
            <img src={convert} className="card-img-top " alt="..." style={{
                width:"74px",
                marginTop:"-4px"
            }}/>
            <div className="card-body" style={{
                marginTop:"4px"
            }}>
                <h5 className="card-title">
                Stock Certificate <br/> to Stock Token</h5>
            </div>
            </div>
        </a>
        </div> */}
      </div>
    </div>
    // </div>
  );
};

export default SubHeader;
