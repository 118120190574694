import { useTheme } from '@emotion/react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dark_loader from '../../../assets/arts/loader/black.gif';
import loader from '../../../assets/arts/loader/wtite.gif';
import { baseCEXURL, getAllStockPrice, marketsData } from '../../../services/api';
import { ETFData } from '../ETFData';
import './ETFTable.css';

const ETFTable = ({ searchText, sort }) => {
  const [sorted, setsorted] = useState([]);
  const [fdata, setFdata] = useState([]);
  const [collData, setCollData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down('md'));
  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setFdata(fullData);
  }, [fullData]);

  useEffect(() => {
    if (searchText === "" || searchText === undefined) {
      setCollData(fdata);
    } else {
      const filteredItems = fdata.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setCollData(filteredItems);
    }
  }, [searchText, fdata]);

  useEffect(() => {
    if (sort === "Name : A to Z") {
      const filteredItems = [...collData].sort((a, b) => (a.name).localeCompare(b.name));
      setsorted(filteredItems);
    }
    else if (sort === "Name : Z to A") {
      const filteredItems = [...collData].sort((a, b) => (b.name).localeCompare(a.name));
      setsorted(filteredItems);
    }
    else if (sort === "Price : High to Low") {
      const filteredItems = [...collData].sort((a, b) => b.value - a.value);
      setsorted(filteredItems);
    }
    else if (sort === "Price : Low to High") {
      const filteredItems = [...collData].sort((a, b) => a.value - b.value);
      setsorted(filteredItems);
    }
    else {
      setsorted(collData);
    }
  }, [sort, collData])

  const fetchData = async () => {
    try {
      // Fetch market data and update ETF values
      const marketDataResponse = await marketsData();
      const updatedETFData = ETFData.map((etf) => {
        const marketETF = marketDataResponse.data.find((m) => m.Symbol === etf.symbol);
        if (marketETF) {
          const roundedPrice = parseFloat(marketETF.Price.toFixed(2));
          return { ...etf, value: roundedPrice };
        }
        return etf;
      });

      setFullData(updatedETFData);
      setFdata(updatedETFData);
      setCollData(updatedETFData);

      // Fetch stock prices from the backend
      const stockPriceResponse = await getAllStockPrice();
      if (stockPriceResponse.status === 200) {
        setCollData(ETFData);
        setFullData(ETFData);
      } else {
        console.error('Failed to fetch stock prices:', stockPriceResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer
      // component={Paper} 
      style={{
        marginTop: 60,
      }}>
       
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell colSpan={5} sx={{ padding: 2, fontSize: "20px" }} align="center">
              Performance: Average Annual Return
            </TableCell>
            <TableCell colSpan={3}></TableCell>
            {/* Add more header cells as needed */}
          </TableRow>
          <TableRow>
            <TableCell>Ticker</TableCell>
            <TableCell>Fund Name</TableCell>
            <TableCell>1Y (%)</TableCell>
            <TableCell>3Y (%)</TableCell>
            <TableCell>5Y (%)</TableCell>
            <TableCell>Incept (%)</TableCell>
            <TableCell>Perf. as of</TableCell>
            <TableCell>Incept Date</TableCell>
            <TableCell>NAV (USD)</TableCell>
            <TableCell align={isMobile ? 'left' : 'right'} sx={{ pr: isMobile ? 0 : 9 }}>Exchange</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow></TableRow>
        {loading ? (  // Conditionally render the loader
        <TableRow style={{backgroundColor:"none"}}>
        <TableCell colSpan={12}>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",  // Adjust the height as needed
          }}
        >
          <img src={theme === "dark" ? dark_loader : loader} alt="" style={{transform:"scale(1.5)"}} />
        </Box>
        </TableCell>
        </TableRow>

      ) : (
        <>

          {sorted.map((collection) => (
            <TableRow
              key={collection.id}
              className='row-link'
              style={{ textDecoration: 'none', color: "#fff" }}
            >
              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  gap: isMobile ? 0.8 : 2
                }}>

                  <Box component={"img"} alt="Collection Image" src={collection.small_logo}
                    sx={{
                      width: `${isMobile ? "15px" : "37px"}`
                    }} />
                  <Box sx={{ alignSelf: "center" }}>
                    {collection.name}
                  </Box>
                </Box>
              </TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.symbol}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.oneY}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.threeY}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.fiveY}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.incept}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.perf_date}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >{collection.incept_date}</TableCell>

              <TableCell
                component={Link}
                to={`/view-etf/${collection.symbol}`}
                style={{ textDecoration: 'none' }}
              >$ {" "}{Math.floor(collection.value * 10000) / 10000} {" "}
                <span style={{ fontSize: `${isMobile ? "6px" : "10px"}`, opacity: "0.9" }}>
                  USD
                </span>
              </TableCell>

              <TableCell align={isMobile ? 'left' : 'right'} >
                <Button
                  variant='contained'
                  disableTouchRipple
                  component={Link}
                  // to={baseCEXURL}
                  to={`${baseCEXURL}/update/home/etf-tokens?buyToken=${collection.symbol}`}
                  sx={{
                    backgroundColor: "#545454",
                    borderRadius: "4px",
                    px: isMobile ? 2 : 6,
                    textTransform: "none",
                    fontSize: `${isMobile ? "10px" : "14px"}`,
                    fontWeight: 400,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#303030",
                      boxShadow: "none",
                    },
                  }}
                >
                  Buy
                </Button>

              </TableCell>
            </TableRow>
          ))}
          </>

      )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ETFTable;
