import {
    Typography,
    Tabs,
    Tab,
    useMediaQuery
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import styles from "./Chart.css";
import baseAPIURL from "../../services/api";
import { useTheme } from '@emotion/react';

const StockChart = ({ symbol, tokenName, logo, companyName }) => {
    const [interval, setInterval] = useState('1week'); // Default interval
    const [chartData, setChartData] = useState([]);
    const [coinValue, setCoinValue] = useState();
    const [coinDefaultValue, setCoinDefaultValue] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        // Fetch data based on interval
        fetch(`${baseAPIURL}/api/v1/inex/basic/stockdata?interval=${interval}&symbol=${symbol}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    let array = data.values.reverse();
                    setCoinDefaultValue(array[0].close);
                    setChartData(array);
                }
            });
    }, [interval, symbol]);
    const dateFormatter3 = (item) => {
        return moment(item).format("ddd, MMM DD, YYYY, HH:mm A");
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            setCoinValue(Math.round(payload[0]?.payload?.open * 100) / 100);
            return (
                <div className="custom-tooltip dark:text-lighthover">
                    <p className="label">{`${dateFormatter3(label)}`}</p>
                </div>
            );
        } else {
            if (label === undefined || typeof label !== Date) {
                label = new Date(Date.now() - 1000 * 53 * 60);
            }
        }
    };

    const width = isMobile ? 295 : 558 ;  
    const height = isMobile ? 172 :328; 

    const dateFormatter = (tickItem) => {
        return moment(tickItem).format("MMM DD");
    };

    const dateFormatter2 = (tickItem) => {
        return moment(tickItem).format("HH:mm A");
    };
    return (
        <div class='flex justify-center'>
            <div class='md:inline-flex text-grey'>
                <Typography variant="text" component="p" fontSize={isMobile ? "30px" : "55px"} fontWeight={200} pl={"20px"}>
                    <img alt="" src={logo} width={isMobile ? "23px" : "auto"}/> {" "}
                    {companyName}
                </Typography>

                <Typography variant="text" component="p" fontSize={isMobile ? "10px" : "15px"} fontWeight={200} mt={1} pl={"20px"} lineHeight={2.1}>
                    {tokenName} NASDAQ:
                </Typography>

                <Typography variant="text" fontSize={isMobile ? "22px" : "35px"} fontWeight={400} mb={3} pl={"20px"}>
                    {symbol === "SPX" ? "S&P500" : (symbol === "AVGO" ? "BCM" : symbol)} $ {isNaN(coinValue) ? (Math.floor(coinDefaultValue * 100) / 100) :(Math.floor(coinValue * 100) / 100)}
                </Typography>
                <Typography variant="text" fontSize={isMobile ? "10px" : "15px"} fontWeight={200} mb={3} style={{ opacity: "0.6" }}>
                    {" "}USD
                </Typography>

                <span>
                    <div style={{ backgroundColor: "var(--background)", 
                    // padding: '20px',
                    marginTop:`${isMobile ? "23px" : "33px"}`
                    }}>
                        <Tabs
                            value={interval}
                            onChange={(event, newValue) => setInterval(newValue)}
                            textColor="var(--color-text)"
                            indicatorColor="primary"
                            className="main-tab"
                        >
                            <Tab label="24H" value={"1day"} className="my-time"/>
                            <Tab label="1W" value={"1week"}  className="my-time"/>
                            <Tab label="1M" value={"1month"} className="my-time" />
                            <Tab label="1Y" value={"1year"} className="my-time" />
                        </Tabs>
                    </div>

                    {/* Chart Display */}
                    <AreaChart
                        margin={{ left: -9, right: -40, top: 10 }}
                        className={styles.graphBackground}
                        width={width}
                        height={height}
                        data={chartData}
                        style={{ background: "var(--background)" }}

                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#006DFF" stopOpacity={0.5} />
                                <stop offset="90%" stopColor="#006DFF" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area
                            dot={false}
                            type="monotone"
                            dataKey="close"
                            strokeWidth={1.5}
                            isAnimationActive={false}
                            stroke="rgba(75, 148, 232, 1)"
                            fill="url(#colorUv)"
                        />
                        <XAxis
                            dataKey="datetime"
                            domain={["dataMin", "dataMax"]}
                            interval="preserveStartEnd"
                            stroke="var(--color-text)"
                            tick={{ fill: "var(--color-text)" }}
                            style={{ fontSize: 13 }}
                            minTickGap={75}
                            tickFormatter={!(interval === "1day") ? dateFormatter : dateFormatter2}
                        />
                        <YAxis
                            stroke="var(--color-text)"
                            padding={{ top: 20 }}
                            tick={{ fill: "var(--color-text)" }}
                            domain={['auto', 'auto']}
                        />
                        <Tooltip
                            className={styles.customTooltip}
                            position={{ y: -15 }}
                            content={<CustomTooltip />}
                            wrapperStyle={{ visibility: "visible" }}
                        />
                    </AreaChart>
                </span>
            </div>
        </div>
    );
};

export default StockChart;
