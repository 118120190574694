import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  // ToggleButton,
  // ToggleButtonGroup,
} from '@mui/material';
import { Box } from '@mui/system';

// Icons
import SearchIconExplore from '../../../assets/arts/searchIconExplore.svg';
import tagicon from '../../../assets/tag1 2.svg';
import certi from '../../../assets/stoc cert logo 1.svg';
// import { FiChevronLeft } from "react-icons/fi";
// import { RiLayoutGridLine, RiListUnordered } from "react-icons/ri"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TuneIcon from '@mui/icons-material/Tune';
// import FilterListIcon from '@mui/icons-material/FilterList';
import HistoryIcon from '@mui/icons-material/History';
// import UpdateIcon from '@mui/icons-material/Update';
// import { BiSort } from 'react-icons/bi';
import { BsSortAlphaDown, BsSortAlphaDownAlt } from 'react-icons/bs';
import FilterTab from "../../FilterTab/FilterTab";

import { StyledMenu } from '../../StyledMenu/StyledMenu';
import SubHeader from '../../SubHeader/SubHeader';
import CertificateTable from './CertificateTable';
const Certifications = () => {
  const [sortOptions, setSorOptions] = useState('Default');
  // const [orderOptions, setOrderOptions] = useState('NEWEST');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  // const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  // const [view, setView] = useState('table');
  // const navigationRef = useRef(null);

  const openSortMenu = Boolean(sortAnchorEl);
  // const openOrderMenu = Boolean(orderAnchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  // const handleViewChange = (event, newView) => {
  //   if (newView !== null) {
  //     setView(newView);
  //   }
  // };
  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  // const handleOpenOrderMenu = (event) => {
  //   setOrderAnchorEl(event.currentTarget);
  // };

  // const handleCloseOrderMenu = () => {
  //   setOrderAnchorEl(null);
  // };

  const searchResults = (event) => {
    setSearchText(event.target.value)
  }

  return (
    <>
    <SubHeader/>

    <Container sx={{
      maxWidth:"1200px",
      mx:"auto",
      pb:8
    }}>

      
    <Box
      // color={darkMode ? '#ffffff' : '#121212'}
      // color={'#ffffff'}
      pt={!isMobile ? 27 : 18}
      pb={3}
    >

      <Typography variant="text" component="p" fontSize={isMobile ? "20px" : "50px"} fontWeight={700} mb={5}>
        <img src={certi} alt="certi" height={isMobile ? "20px" : "45px"} style={{marginBottom:`${isMobile ? "9px" : "18px"}`}}/> {" "}
        Indexx Stock Certificate
      </Typography>

      {!isMobile ? (
        <Stack direction='row' alignItems='center' gap={2}>
          <Box width='50%'>
            <Input
              disableUnderline
              fullWidth
              placeholder="Search in Certificates"
              sx={{
                background: 'var(--background_lighter)',
                color:"var(--color-text)",
                border: 'none',
                py: 1.6,
                px: 2,
                borderRadius: '5px',
              }}
              onChange={searchResults}
              startAdornment={
                <InputAdornment position='start'>
                  <img src={SearchIconExplore} alt='Search' />
                </InputAdornment>
              }
            />
          </Box>
          <Box width='50%'>
            <Button
              onClick={handleOpenSortMenu}
              sx={{
                py: 0.7,
                // width: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                boxShadow:"none",
                background: 'var(--background_lighter)',
                color:"var(--color-text)",
                "&:hover": {
                    boxShadow:"none",
                background: 'var(--background_lighter)',
                color:"var(--color-text)",
                  },
              }}
              variant='contained'
              // color='accent'
              fullWidth
              endIcon={
                <Box color='secondary' mt={1.5}>
                  {openSortMenu ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Box>
              }
            >
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant='subtitle1'
                // color='secondary'
              >
                {'Sort By'}
              </Typography>
              <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                {sortOptions}
              </Typography>
            </Button>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  width: "38.5%",
                  mt:3,
                },
              }}
              TransitionComponent={Grow}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions('Default');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25, fill:"var(--color-text)"}} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'Default'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Price : Low to High');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  {/* <HistoryIcon style={{ height: 25, width: 25, fill:"var(--color-text)" }} /> */}
                  <img src={tagicon} alt="" style={{ height: 25, width: 25, }}/>
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'Price : Low to High'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Price : High to Low');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  {/* <HistoryIcon style={{ height: 25, width: 25, fill:"var(--color-text)" }} /> */}
                  <img src={tagicon} alt="" style={{ height: 25, width: 25, }}/>
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'Price : High to Low'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Name : A to Z');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <BsSortAlphaDown style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {'Name : A to Z'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Name : Z to A');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <BsSortAlphaDownAlt style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {'Name : Z to A'}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          {/* <Box width='31%'>
            <Button
              onClick={handleOpenOrderMenu}
              sx={{
                py: 0.7,
                // width: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                background:"#171717",
                "&:hover": {
                    backgroundColor : "#171717",
                  },
              }}
              variant='contained'
              // color='accent'
              fullWidth
              endIcon={
                <Box color='secondary' mt={1.5}>
                  {openOrderMenu ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Box>
              }
            >
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant='subtitle1'
                // color='secondary'
              >
                {'ORDER_BY'}
              </Typography>
              <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                {orderOptions}
              </Typography>
            </Button>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  width: "24%",
                },
              }}
              TransitionComponent={Grow}
              anchorEl={orderAnchorEl}
              open={openOrderMenu}
              onClose={handleCloseOrderMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setOrderOptions('NEWEST');
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'NEWEST'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOrderOptions('OLDEST');
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <UpdateIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'OLDEST'}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box> */}

          {/* <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleViewChange}
      style={{  
        // backgroundColor: `${darkMode ? '#171717' : '#eeeeee'}`
        background: '#171717',
        
         }} // Set the background color here
    >
      <ToggleButton value="container" style={{padding:"16px", border:"none"}}>
        <RiLayoutGridLine style={{fontSize:"22px"}}/>
      </ToggleButton>
      <ToggleButton value="table" style={{padding:"16px", border:"none"}}>
        <RiListUnordered style={{fontSize:"22px"}}/>
      </ToggleButton>
    </ToggleButtonGroup> */}
        </Stack>
      ) : (
        <Stack direction='row' alignItems='center' gap={2}>
          <Box width='80%'>
            <OutlinedInput
              size='small'
              fullWidth
              placeholder="Search in Certificates"
              onChange={searchResults}
              sx={{
                // background: `${darkMode ? '#171717' : '#EEEEEE'}`,
                background: '#171717',
                color:"#fff",
                border: 'none',
                py: isMobile ? 0.8 : 1.6,
                px: isMobile ? 2 : 2,
                borderRadius: '5px',
              }}
              startAdornment={
                <InputAdornment position='start'>
                  <img
                    height={15}
                    width={15}
                    src={SearchIconExplore}
                    alt='Search'
                  />
                </InputAdornment>
              }
            />
          </Box>
          {/* Sort */}
          <Box>
            <IconButton
              onClick={handleOpenSortMenu}
              variant='contained'
              fullWidth
            >
              <TuneIcon sx={{fill:"var(--dark_text)"}}/>
            </IconButton>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  // backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                  backgroundColor: '#040404',
                },
              }}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions('Default');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25, fill:"var(--color-text)"}} />
                  <Typography variant='subtitle1' fontWeight={400}>
                  {'Default'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Price : Low to High');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                <img src={tagicon} alt="" style={{ height: 25, width: 25, }}/>
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {'Price : Low to High'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Price : High to Low');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                <img src={tagicon} alt="" style={{ height: 25, width: 25, }}/>
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                    {'Price : High to Low'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Name : A to Z');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                <BsSortAlphaDown style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                  {'Name : A to Z'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSorOptions('Name : Z to A');
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                <BsSortAlphaDownAlt style={{ height: 25, width: 25 }} />
                  <Typography ml={1.5} variant='subtitle1' fontWeight={400}>
                  {'Name : Z to A'}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          {/* <Box>
            <IconButton
              onClick={handleOpenOrderMenu}
              variant='contained'
              fullWidth
            >
              <FilterListIcon />
            </IconButton>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  // backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                  backgroundColor: '#040404',
                },
              }}
              TransitionComponent={Grow}
              anchorEl={orderAnchorEl}
              open={openOrderMenu}
              onClose={handleCloseOrderMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setOrderOptions('NEWEST');
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'NEWEST'}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOrderOptions('OLDEST');
                  handleCloseOrderMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <UpdateIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {'OLDEST'}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box> */}
          {/* <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            sx={{
              // backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
              backgroundColor: '#040404',
            }}
          >
            <ToggleButton value="container">
              <RiLayoutGridLine style={{fontSize:"22px"}}/>
            </ToggleButton>
            <ToggleButton value="table">
              <RiListUnordered style={{fontSize:"22px"}}/>
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Stack>
      )}
      <Box mb={3} mt={2}>

        <FilterTab />
      </Box>

      {/* {view === 'container' ? (
      <Details searchText={searchText} view={view} />
      ) : ( */}
        <CertificateTable searchText={searchText} sort={sortOptions}/>
      {/* )} */}

    </Box>

    </Container>
    </>

  );
};

export default Certifications;
