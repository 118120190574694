import React, {useState, useEffect} from "react";
import img1 from "../../assets/arts/how_to_certi/1.svg";
import img2 from "../../assets/arts/how_to_certi/2.svg";
import img3 from "../../assets/arts/how_to_certi/3.svg";
import img4 from "../../assets/arts/how_to_certi/4.svg";

import img1_light from "../../assets/arts/how_to_certi/light/1.svg";
import img2_light from "../../assets/arts/how_to_certi/light/2.svg";
import img3_light from "../../assets/arts/how_to_certi/light/3.svg";
import img4_light from "../../assets/arts/how_to_certi/light/4.svg";

import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { baseShopURL } from "../../services/api";

const HowtoBuyCert = () => {

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <Typography fontSize={"60px"} textAlign="center">
        How to Buy Stock Certificate
      </Typography>
      <Box className="d-flex justify-content-center" mt={10} mb={15}>
        <Link
          to="/certificates?type=all"
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            1. Select Stock Certificate
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img1 : img1_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to="/view-certificate/TSLA"
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
              2. Look at the details and click the “Buy Stock Certificate” button
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img2 : img2_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to={`${baseShopURL}/products/indexx-tesla-stock-token`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            3. Select an amount to buy, add to cart then pay using Paypal
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img3 : img3_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseShopURL}/products/indexx-tesla-stock-token`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left" minWidth={"656px"}>
            4. Log in to your PayPal account and complete the payment process
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img4 : img4_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
    </div>
  );
};

export default HowtoBuyCert;
