import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ReactCardFlip from "react-card-flip";

import indexx_logo from "../../assets/logo/indexx 2.svg";
import certi from '../../assets/stoc cert logo 1.svg';
import { baseShopURL } from "../../services/api";

import { Link, useParams } from 'react-router-dom';
import StockChart from '../Charts/Chart';
import SubHeader from '../SubHeader/SubHeader';
import { CollectionData } from '../Collections/CollectionData';
import { useTheme } from '@emotion/react';

const NewCertificateDetails = () => {

    const { symbol } = useParams();

    const allDetails = CollectionData.filter((item) => item.symbol === symbol)[0]
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [flip1, setFlip1] = useState(false);
    const [flip2, setFlip2] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <SubHeader />
            {!isMobile ? 
            <Box pt={26} pb={5}>
                <Box>
                    {/* <Typography variant="text" component="p" fontSize={"20px"} fontWeight={200} mt={3} mb={5}>
                        "Pioneering the Future as the World's First!"
                    </Typography> */}
                    <Typography variant="text" component="p" fontSize={isMobile ? "20px" : "50px"} fontWeight={700} mb={5}>
                        <img src={certi} alt="certi" height={isMobile ? "20px" : "45px"} style={{marginBottom:`${isMobile ? "9px" : "18px"}`}}/> {" "}
                        Indexx Stock Certificate
                    </Typography>
                    <Typography variant="text" component="p" fontSize={"35px"} fontWeight={700} >
                        Indexx {allDetails.name} Stock Certificate
                    </Typography>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }} mt={2}>
                        <Box pb={"20px"} style={{
                            width: '558px',
                            height: '600px',
                            margin: '20px',
                            textAlign: 'left',
                        }}>
                            <StockChart symbol={(symbol === 'BCM' ? 'AVGO' : symbol === "S&P500" ? "SPX" : symbol)} tokenName={allDetails?.tokenName} logo={allDetails?.logo} companyName={allDetails?.companyName} />
                        </Box>

                        <Box style={{
                            width: '558px',
                            height: '600px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px'
                        }}>
                            <img alt="" src={indexx_logo} height={"55px"} style={{ marginBottom: "15px" }} />

                            <Typography variant="text" component="p" fontSize={"15px"} fontWeight={200} lineHeight={2.1}>
                                Indexx {allDetails?.name} Stock Certificate
                            </Typography>

                            <Typography variant="text" fontSize={"35px"} fontWeight={400} minWidth={"132px"} display={"inline-block"}>
                                {symbol}
                            </Typography>

                            <Typography variant="text" fontSize={"35px"} fontWeight={400} mb={3}>
                                {" "} $ {allDetails?.value}
                            </Typography>

                            <Typography variant="text" fontSize={"15px"} fontWeight={200} mb={3} style={{ opacity: "0.6" }}>
                                {" "}USD
                            </Typography>

                            <Typography variant="text" component="p" fontSize={"12px"} fontWeight={200} mb={2} style={{ opacity: "0.6" }} ml={"28%"}>
                                1 {symbol} Stock = 1000 Stock Certificate
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    // height: "300.35px",
                                    width: "357px",
                                }}
                                alt="chart"
                                src={allDetails?.certi_photo}
                                // mt={2.8}
                                mb={3}
                            // mx={3}
                            />

                            {/* <img alt='open' src={plus} className='plus-img-right' onClick={() => setFliptsla2(!fliptsla2)} /> */}
                        </Box>

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }} mt={2}>

                        <Box style={{
                            width: '558px',
                            height: '300px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px',
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Box sx={{ top: "50%" }}>
                                <Typography variant="text" component="p" fontSize={"20px"} fontWeight={500} mb={4}>
                                    {allDetails.text_left_top}
                                </Typography>

                                <Typography variant="text" component="p" fontSize={"13px"} fontWeight={200} lineHeight={2.5} >
                                    {allDetails.text_left}
                                </Typography>
                            </Box>
                        </Box>

                        <Box style={{
                            width: '558px',
                            height: '300px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px',

                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                // mt: 0.7
                            }}>
                                <Typography variant="text" component="p" fontSize={"20px"} fontWeight={500} mb={4}>
                                    Indexx {symbol} Stock Certificate
                                </Typography>
                                <Typography variant="text" component="p" fontSize={"13px"} fontWeight={200} lineHeight={2.5} >
                                    {allDetails.certi_right}
                                </Typography>

                            </Box>


                        </Box>
                    </Box>
                </Box>
                <Box
                    // className="buy-parent"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        mt:4
                    }}
                >

                    <Button
                        sx={{
                            backgroundColor: "#454545",
                            borderRadius: "0",
                            px: 4,
                            py: 1.5,
                            width: "400px",
                            zIndex: "5",
                            "&:hover": {
                                background: "#252525",
                            },
                        }}
                    >
                        <Link to={`${baseShopURL}/collections/indexx-stock-token-tickets`} style={{ textDecoration: "none" }}>
                            <Typography
                                component="span"
                                color="#e0e0e0"
                                sx={{ textTransform: "none" }}
                                fontWeight={100}
                                fontFamily={"Inter"}
                                fontSize={"13px"}
                            >
                                Buy Stock Certificate
                            </Typography>
                        </Link>
                    </Button>
                </Box>

            </Box>
            :
            <Box pt={20} pb={5} >
                <Box>
                    <Typography variant="text" component="p" fontSize={"20px"} fontWeight={700} >
                        Indexx {allDetails.name} Stock Certificate
                    </Typography>

                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center", gap:3 }} mt={2}>
                        <ReactCardFlip isFlipped={flip1}
                        flipDirection="horizontal">
                        <Box pb={"20px"} style={{
                            width: '295px',
                            height: '430px',
                            margin: '20px',
                            paddingBlock: '20px',
                            textAlign: 'left',
                            borderRadius: '2px',
                            borderBlock:"1px solid #434343",
                        }}>
                            <StockChart symbol={(symbol === 'BCM' ? 'AVGO' : symbol === "S&P500" ? "SPX" : symbol)} tokenName={allDetails?.tokenName} logo={allDetails?.logo} companyName={allDetails?.companyName} />
                            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Button
                                    onClick={() => setFlip1(!flip1)}
                                    disableTouchRipple
                                    sx={{
                                        fontSize: "10px",
                                        color: "#e0e0e0",
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        boxShadow: 'none',
                                        mt:2.5,
                                        width: "fit-content",
                                        height: "fit-content",
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            color: "#e0e0e0",
                                        },
                                    }}
                                >
                                    See more
                                </Button>
                                </Box>
                        </Box>

                        <Box style={{
                            width: '295px',
                            height: '430px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px',
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: '2px',
                            borderBlock:"1px solid #434343",
                            justifyContent:"flex-end"
                        }}>
                                <Typography variant="text" component="p" fontSize={"20px"} fontWeight={500} mb={4}>
                                    {allDetails.text_left_top}
                                </Typography>

                                <Typography variant="text" component="p" fontSize={"11px"} fontWeight={200} lineHeight={"22px"} >
                                    {allDetails.text_left}
                                </Typography>
                                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Button
                                    onClick={() => setFlip1(!flip1)}
                                    disableTouchRipple
                                    sx={{
                                        fontSize: "10px",
                                        color: "#e0e0e0",
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        boxShadow: 'none',
                                        mt:8.5,
                                        width: "fit-content",
                                        height: "fit-content",
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            color: "#e0e0e0",
                                        },
                                    }}
                                >
                                    See less
                                </Button>
                                </Box>
                        </Box>
                        
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={flip2}
                        flipDirection="horizontal" >
                        
                        <Box style={{
                            width: '295px',
                            height: '430px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px',
                            borderRadius: '2px',
                            borderBlock:"1px solid #434343",
                        }}>
                            <img alt="" src={indexx_logo} height={"27px"} style={{ marginBottom: "15px" }} />

                            <Typography variant="text" component="p" fontSize={"11px"} fontWeight={200} lineHeight={2.1}>
                                Indexx {allDetails.name} Stock Certificate
                            </Typography>

                            <Typography variant="text" fontSize={"23px"} fontWeight={400} minWidth={"90px"} display={"inline-block"}>
                                {symbol}
                            </Typography>

                            <Typography variant="text" fontSize={"23px"} fontWeight={400} mb={3}>
                                {" "} $ {allDetails.value}
                            </Typography>

                            <Typography variant="text" fontSize={"8px"} fontWeight={200} mb={3} style={{ opacity: "0.6" }}>
                                {" "}USD
                            </Typography>

                            <Typography variant="text" component="p" fontSize={"7px"} fontWeight={200} mb={2} style={{ opacity: "0.6" }} ml={"38%"}>
                                1 {symbol} Stock = 1000 Stock Certificate
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    // height: "300.35px",
                                    width: "100%",
                                }}
                                alt="chart"
                                src={allDetails?.certi_photo}
                                // mt={2.8}
                                mb={3}
                            />
                                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Button
                                    onClick={() => setFlip2(!flip2)}
                                    disableTouchRipple
                                    sx={{
                                        fontSize: "10px",
                                        color: "#e0e0e0",
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        boxShadow: 'none',
                                        mt:10,
                                        width: "fit-content",
                                        height: "fit-content",
                                        // position: "relative",
                                        // bottom: "-17px",
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            color: "#e0e0e0",
                                        },
                                    }}
                                >
                                    See more
                                </Button>
                                </Box>
                        </Box>

                        <Box style={{
                            width: '295px',
                            height: '430px',
                            margin: '20px',
                            textAlign: 'left',
                            padding: '20px',
                            borderRadius: '2px',
                            borderBlock: "1px solid #434343",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent:"flex-end"
                        }}>
                                <Typography variant="text" component="p" fontSize={"11px"} fontWeight={500} mb={3}>
                                    Indexx {symbol} Stock Certificate
                                </Typography>
                                <Typography variant="text" component="p" fontSize={"11px"} fontWeight={200} lineHeight={"22px"} minHeight={"220px"}>
                                    {allDetails.certi_right}
                                </Typography>
                                <Typography variant="text" component="p" fontSize={"15px"} fontWeight={200} mt={2}>
                                    Pioneering the Future as the World's First!
                                </Typography>

                                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Button
                                    onClick={() => setFlip2(!flip2)}
                                    disableTouchRipple
                                    sx={{
                                        fontSize: "10px",
                                        color: "#e0e0e0",
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        boxShadow: 'none',
                                        // mt:3,
                                        width: "fit-content",
                                        height: "fit-content",
                                        // position: "relative",
                                        // bottom: "-17px",
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            color: "#e0e0e0",
                                        },
                                    }}
                                >
                                    See less
                                </Button>
                                </Box>


                        </Box>
                        </ReactCardFlip>
                    </Box>
                </Box>
                <Box
                    // className="buy-parent"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        mt:4
                    }}
                >

                    <Button
                        sx={{
                            backgroundColor: "#454545",
                            borderRadius: "0",
                            px: 4,
                            py: 1.5,
                            width: "75%",
                            zIndex: "5",
                            "&:hover": {
                                background: "#252525",
                            },
                        }}
                    >
                        <Link to={`${baseShopURL}/collections/indexx-stock-token-tickets`} style={{ textDecoration: "none" }}>
                            <Typography
                                component="span"
                                color="#e0e0e0"
                                sx={{ textTransform: "none" }}
                                fontWeight={100}
                                fontFamily={"Inter"}
                                fontSize={"13px"}
                            >
                                Buy Stock Certificate
                            </Typography>
                        </Link>
                    </Button>
                </Box>

            </Box>
            }
        </>
    )
}

export default NewCertificateDetails