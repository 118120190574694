import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, useMediaQuery } from '@mui/material';
import { CollectionData } from '../CollectionData';
import { Link, useLocation } from 'react-router-dom';
import { baseCEXURL, getAllStockPrice } from '../../../services/api';
import './CollectionsTable.css';
import { useTheme } from '@emotion/react';
import dark_loader from '../../../assets/arts/loader/black.gif';
import loader from '../../../assets/arts/loader/wtite.gif';

const CollectionsTable = ({ searchText, sort }) => {
  const [sorted, setsorted] = useState([]);
  const [fdata, setFdata] = useState([]);
  const [collData, setCollData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const tag = useMemo(() => new URLSearchParams(search).get("type"), [search]) || "all";
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down('md'));
  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  console.log(sort);

  useEffect(() => {
    if (tag === "all") {
      setFdata(fullData);
    }
    else {
    const filteredItems = fullData.filter(item =>
      item.tier.toLowerCase().includes(tag.toLowerCase())
    );
    setFdata(filteredItems);
    }
  }, [tag, fullData]);

  useEffect(() => {
    if (searchText === "" || searchText === undefined) {
      setCollData(fdata);
    } else {
      const filteredItems = fdata.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setCollData(filteredItems);
    }
  }, [searchText, fdata]);

  useEffect(() => {
    if(sort === "Name : A to Z") {
      const filteredItems = [...collData].sort((a,b) => (a.name).localeCompare(b.name));
      setsorted(filteredItems);
    }
    else if(sort === "Name : Z to A") {
      const filteredItems = [...collData].sort((a,b) => (b.name).localeCompare(a.name));
      setsorted(filteredItems);
    }
    else if(sort === "Price : High to Low") {
      const filteredItems = [...collData].sort((a,b) => b.value - a.value);
      setsorted(filteredItems);
    }
    else if(sort === "Price : Low to High") {
      const filteredItems = [...collData].sort((a,b) => a.value - b.value);
      setsorted(filteredItems);
    }
    else {
      setsorted(collData);
    }
  }, [sort, collData])
  

  useEffect(() => {
    // Fetch stock prices from the backend
    const fetchData = async () => {
      try {
        const data = await getAllStockPrice();
        if (data.status === 200) {
          const updatedData = CollectionData.map(item => {
            // Find the corresponding stock price using the symbol
            const stockInfo = data.data.find(stock => stock.token === item.symbol);
            // If a matching stock price is found, update the value
            if (stockInfo) {
              return {
                ...item,
                value: stockInfo.value
              };
            }
            // Otherwise, return the original item
            return item;
          });
          setCollData(updatedData); // Use the updated data
          setFullData(updatedData);
        } else {
          console.error('Failed to fetch stock prices:', data);
        }
      }catch (err) {
        console.error('Error fetching stock prices:', err);
      } finally {
        // Set loading state to false when fetching is complete (whether it was successful or not)
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <TableContainer 
    // component={Paper} 
    style={{
      marginTop:40,
    }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stock Token</TableCell>
           
            <TableCell>Tier</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>Value</TableCell>
            <TableCell align={isMobile ? 'left' : 'right'} sx={{pr:isMobile ? 0 : 13}}>Shop</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow></TableRow>
        {loading ? (  // Conditionally render the loader
        <TableRow style={{backgroundColor:"none"}}>
        <TableCell colSpan={12}>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",  // Adjust the height as needed
          }}
        >
          <img src={theme === "dark" ? dark_loader : loader} alt="" style={{transform:"scale(1.5)"}} />
        </Box>
        </TableCell>
        </TableRow>

      ) : (
        <>

          {sorted.map((collection) => (
            <TableRow
              key={collection.id}
              className='row-link'
              style={{ textDecoration: 'none', color:"#fff" }}
            >
              <TableCell 
              component={Link}
              to={`/view-detail/${collection.symbol}`}
              style={{ textDecoration: 'none'}}
              >
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                gap:isMobile ? 0.8 : 2
              }}>

                <Box component={"img"} alt="Collection Image" src={collection.logo} 
                sx={{ 
                  width: `${isMobile ? "15px" :  "25px"}`   
                  }} />
                <Box sx={{alignSelf:"center"}}>
                  {collection.name}
                </Box>
              </Box>
              </TableCell>
             
              <TableCell
              component={Link}
              to={`/view-detail/${collection.symbol}`}
              style={{ textDecoration: 'none'}}
              >{collection.tier}</TableCell>
              <TableCell
              component={Link}
              to={`/view-detail/${collection.symbol}`}
              style={{ textDecoration: 'none'}}
              >{collection.symbol}</TableCell>
              <TableCell
              component={Link}
              to={`/view-detail/${collection.symbol}`}
              style={{ textDecoration: 'none'}}
              >$ {" "}{Math.floor(collection.value* 10000)/ 10000} {" "}
              <span style={{fontSize:`${isMobile ? "6px" : "10px"}`, opacity:"0.9"}}>
              USD
              </span>              
              </TableCell>

              <TableCell align={isMobile ? 'left' : 'right'} >
              <Button
                        variant='contained'
                        disableTouchRipple
                        component={Link}
                        to={`${baseCEXURL}/update/home/stock-token?buyToken=${collection.symbol}`}
                        sx={{
                            backgroundColor: "#545454",
                            borderRadius: "4px",
                            px: isMobile ? 2 : 6,
                            textTransform: "none",
                            fontSize: `${isMobile ? "10px" : "14px"}`,
                            fontWeight: 400,
                            boxShadow: "none",
                            "&:hover": {
                            backgroundColor: "#303030",
                            boxShadow: "none",
                            },
                        }}
                        >
                          Buy
                        </Button>
              
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollectionsTable;
