import React, { useState, useEffect } from "react";
import blockchain from "../../../assets/third-header/block chain white 1.svg";
import tag from "../../../assets/third-header/tag3 1.svg";
import pros from "../../../assets/third-header/pros cons 1.svg";
import question from "../../../assets/third-header/question 1.svg";
import vs from "../../../assets/third-header/vs 1.svg";

import tag_light from "../../../assets/third-header/light-icons/tag3 2.svg";
import question_light from "../../../assets/third-header/light-icons/question 2.svg";
import vs_light from "../../../assets/third-header/light-icons/vs 2.svg";
import pros_light from "../../../assets/third-header/light-icons/pros cons 1.svg";
import blockchain_light from "../../../assets/third-header/light-icons/block chain white 1.svg";

import compare_etf from "../../../assets/arts/compare_etf.svg";
import working_etf from "../../../assets/arts/working_etf.svg";
import diff_etf from "../../../assets/arts/diff_etf.svg";

import compare_etf_light from "../../../assets/arts/learn-more-light/compare_etf.svg";
import working_etf_light from "../../../assets/arts/learn-more-light/working_etf.svg";
import diff_etf_light from "../../../assets/arts/learn-more-light/diff_etf.svg";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import "./LearnETF.css";
import SubHeader from "../../SubHeader/SubHeader";
import ETFFAQ from "./ETFFAQ";
import HowtoBuyETF from "./HowtoBuyETF";

const TabPanel = ({ index, value, children }) => (
    <div hidden={value !== index} style={{ marginTop: '20px' }}>
      {value === index && <div>{children}</div>}
    </div>
  );

const LearnETF = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0,0);
  };

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <SubHeader />

      <Box sx={{
        position: "fixed",
        top: "170px",
        width: "100%",
        zIndex: "999",
        background: "var(--background)",
        color:"var(--color-text)",
        height: "100px",
      }}>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example"
          centered
          className="learn-tabs"
        >
          <Tab
            icon={<img src={theme === "dark" ? tag :tag_light} alt="Home" width={"30px"} height={"27px"} />}
            label="How to Buy ETF"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? blockchain : blockchain_light} alt="Home" width={"29px"} />}
            label="ETF on Blockchain"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? pros : pros_light} alt="Home" width={"30px"} height={"27px"} />}
            label="Benefits & Limitations"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? vs : vs_light} alt="Home" width={"31px"} height={"25px"}/>}
            label="ETF Comparison"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? question : question_light} alt="Home" width={"30px"} />}
            label="FAQ"
            disableRipple
          />
        </Tabs>
      </Box>
      <Box pt={40} pb={3}>

      <TabPanel value={value} index={0}>
        <HowtoBuyETF />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Typography fontSize={"60px"} textAlign="center">
          How ETF works on Blockchain
        </Typography>
        <Box
          component={"img"}
          src={theme === "dark" ? working_etf : working_etf_light}
          alt='logo'
          my={10}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography fontSize={"60px"} textAlign="center">
          ETF Benefits & Limitations
        </Typography>
        <Box
          component={"img"}
          src={theme === "dark" ? compare_etf : compare_etf_light}
          alt='logo'
          my={10}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Typography fontSize={"60px"} textAlign="center">
          Indexx ETFs vs Normal ETFs
        </Typography>
        <Box
          component={"img"}
          src={theme === "dark" ? diff_etf : diff_etf_light}
          alt='logo'
          my={10}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography fontSize={"60px"} textAlign="center" mb={5}>
          Frequently Asked Questions
        </Typography>
        <ETFFAQ/>
      </TabPanel>
      </Box>

    </div>
  );
};

export default LearnETF;
