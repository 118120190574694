import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import './LearnCertificate.css'

const accordionData = [
  {
    id: 1,
    question: "What are Indexx Stock Certificates?",
    answer: "Indexx Stock Certificates are digital representations of ownership in a company, pegged to real tokens and managed on a blockchain. They offer a unique blend of traditional stock ownership and the benefits of blockchain technology.",
  },
  {
    id: 2,
    question: "How are Indexx Stock Certificates different from traditional stocks?",
    answer: "Indexx Stock Certificates are digital assets pegged to real tokens, traded on a centralized exchange with features like staking opportunities and global accessibility. Traditional stocks are typically traded on stock exchanges and may lack such token-based functionalities.",
  },
  {
    id: 3,
    question: "Can I trade Indexx Stock Certificates with other cryptocurrencies?",
    answer: "Yes, you can trade Indexx Stock Certificates on our centralized exchange, providing the flexibility to trade with other cryptocurrencies and tokens.",
  },
  {
    id: 4,
    question: "What is the pegging mechanism for Indexx Stock Certificates?",
    answer: "Indexx Stock Certificates are pegged to real tokens, providing stability and transparency. This pegging mechanism ensures that the value is tied to tangible assets.",
  },
  {
    id:5,
    question:"Are there staking opportunities for Indexx Stock Certificates?",
    answer:"Absolutely. Indexx Stock Certificates offer staking opportunities, allowing users to earn additional rewards by staking their certificates.",
  },
  {
    id:6,
    question:"How does the decentralized nature of Indexx Stock Certificates benefit investors?",
    answer:"The decentralized nature of Indexx Stock Certificates, facilitated by blockchain technology, brings benefits such as increased security, transparency, and potential community involvement in governance.",
  },
  {
    id:7,
    question:"Can I receive dividends or rewards with Indexx Stock Certificates?",
    answer:"While traditional stocks may offer dividends, Indexx Stock Certificates may provide rewards through staking programs and other token-based benefits.",
  },
  {
    id:8,
    question:"Is there a difference in market accessibility between Indexx Stock Certificates and traditional stocks?",
    answer:"Yes, Indexx Stock Certificates are accessible globally without traditional market restrictions, potentially offering faster and more accessible international transactions compared to traditional stocks.",
  },
  {
    id:9,
    question:"How are Indexx Stock Certificates issued?",
    answer:"Indexx Stock Certificates are managed and issued by the Indexx platform, offering a digital representation of ownership on the blockchain.",
  },
  {
    id:10,
    question:"Are Indexx Stock Certificates available in physical form?",
    answer:"No, Indexx Stock Certificates exist in digital form on the blockchain. They are not issued in physical certificate form.",
  },
];


const CertificateFAQ = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedAccordion, setExpandedAccordion] = React.useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <div>
      <Box width={"80%"} mx={"auto"} pt={2}>
      {accordionData.map((item) => (
        <Accordion
          defaultExpanded
          disableGutters
          sx={{
            backgroundColor: "var(--background)",
            color: "var(--color-text)",
            boxShadow:"none",
            border:"none",
          }}
          key={item.id}
          expanded={expandedAccordion === item.id}
          onChange={handleAccordionChange(item.id)}
        >
          <AccordionSummary
            sx={{
              color: "var(--color-text)",
              mb:0,
              border:"none",
            }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            
          >
            {expandedAccordion === item.id ? 
                <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretDown color="var(--color-text)" />
                </Typography>
             :
            <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretRight color="var(--color-text)" />
             </Typography>
            }

            <Typography variant="subtitle1" fontSize={"25px"} fontWeight={100} textAlign={"left"}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                color: "var(--color-text)",
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
                pt:0,
                pb:5
              }}
              ml={4}
            >
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default CertificateFAQ;
