import tesla_logo from "../../assets/logo/tesla.svg";
import google_logo from "../../assets/logo/google.svg";
import msft_logo from "../../assets/logo/microsoft.svg";
import pepsi_logo from "../../assets/logo/pepsi.svg";
import amzn_logo from "../../assets/logo/amazon.svg";
import apple_logo from "../../assets/logo/apple.svg";
import bcm_logo from "../../assets/logo/broadcom.svg";
import meta_logo from "../../assets/logo/meta.svg";
import nvidia_logo from "../../assets/logo/nvidia.svg";
import sp_logo from "../../assets/logo/sp.svg";

import amzn_token from "../../assets/token_images/amazon_1.svg";
import tesla_token from "../../assets/token_images/Tesla-1.svg";
import apple_token from "../../assets/token_images/apple-1.svg";
import broadcom_token from "../../assets/token_images/BCM-.svg";
import google_token from "../../assets/token_images/google-1.svg";
import msft_token from "../../assets/token_images/microsoft-1.svg";
import nvid_token from "../../assets/token_images/nvidia-.svg";
import meta_token from "../../assets/token_images/meta_.svg";
import pepsi_token from "../../assets/token_images/pepsi_.svg";
import sp5_token from "../../assets/token_images/sp500-.svg";

import amzn_certi from "../../assets/certificates/amazon.svg";
import tesla_certi from "../../assets/certificates/tsla.svg";
import apple_certi from "../../assets/certificates/appl.svg";
import broadcom_certi from "../../assets/certificates/bcm.svg";
import google_certi from "../../assets/certificates/google.svg";
import msft_certi from "../../assets/certificates/msft.svg";
import nvid_certi from "../../assets/certificates/nvda.svg";
import meta_certi from "../../assets/certificates/Meta.svg";
import pepsi_certi from "../../assets/certificates/pepsi.svg";
import sp5_certi from "../../assets/certificates/P&P500.svg";
import { getAllStockPrice } from "../../services/api";


// // Function to fetch updated values and update CollectionData
// function updateValuesFromBackend() {
//   // Fetching updated values
//   getAllStockPrice()
//     .then(data => {
//       console.log(data.data);
//       if (data.status === 200) {
//         // Loop through the received data
//         for (let updatedStock of data.data) {
//           // Find the item in CollectionData and update it
//           for (let item of CollectionData) {
//             if (item.symbol === updatedStock.symbol) {
//               item.value = updatedStock.value;
//               break;
//             }
//           }
//         }
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching updated values:', error);
//     });
// }

// // Call the function to perform the update
// updateValuesFromBackend();

const fetchUpdatedPrices = async () => {
  try {
    const response = await getAllStockPrice();
    console.log(response)
    const jsonData = response;

    if (jsonData.status === 200) {
      return jsonData.data;
    } else {
      throw new Error('Failed to fetch updated prices.');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};


const updateCollectionDataWithPrices = async () => {
  const updatedPrices = await fetchUpdatedPrices();

  if (updatedPrices) {
    for (const updatedStock of updatedPrices) {
      const stockToUpdate = CollectionData.find(stock => stock.symbol === updatedStock.token);

      if (stockToUpdate) {
        stockToUpdate.value = (Math.floor((updatedStock.value * 10000)) / 10000).toString();
      }
    }
  }
};

updateCollectionDataWithPrices();


export const CollectionData = [
  {
    id: "1",
    name: "TESLA",
    symbol: "TSLA",
    tier: "High",
    value: "0.24435",
    shop: "",
    logo: tesla_logo,
    tokenName: "Tesla Stock",
    companyName: "Tesla Inc",
    token_photo: tesla_token,
    text_left_top: "Tesla",
    text_left: "Tesla (TSLA) stock represents ownership in Tesla, Inc., a groundbreaking electric vehicle and sustainable energy company led by Elon Musk. TSLA stock has experienced remarkable growth and volatility, attracting both long-term investors and traders. Its performance is closely tied to Tesla's innovations, production capacity, and global EV market trends.",
    text_right: "IndexX Tesla Stock Token is a digital asset that mirrors the real-time performance of Tesla, Inc. (TSLA) stock on the blockchain. It offers a seamless way for investors to gain exposure to Tesla's price movements without owning the actual shares. This token is part of the decentralized finance (DeFi) ecosystem, providing an innovative and efficient method to engage with Tesla's stock market performance.",
    certi_photo: tesla_certi,
    certi_right: "IndexX Tesla Stock Certificate is a digital asset that mirrors the real-time performance of Tesla, Inc. (TSLA) stock on the blockchain. It offers a seamless way for investors to gain exposure to Tesla's price movements without owning the actual shares. This token is part of the decentralized finance (DeFi) ecosystem, providing an innovative and efficient method to engage with Tesla's stock market performance.",
  },
  {
    id: "2",
    name: "Google",
    symbol: "GOOGL",
    tier: "High",
    value: "0.12956",
    shop: "",
    logo: google_logo,
    tokenName: "Google Stock",
    companyName: "Google",
    token_photo: google_token,
    text_left_top: "Google",
    text_left: "GOOGL stock represents ownership in Alphabet Inc., Google's parent company. Alphabet is a global technology giant with its core business in search, advertising, and cloud computing. GOOGL stock is favored by investors for its strong revenue streams and market dominance, making it a cornerstone of many tech-focused portfolios.",
    text_right: "IndexX GOOGL Stock Token is a digital asset that accurately tracks the real-time performance of Alphabet Inc. (GOOGL) stock on the blockchain. It provides investors with a convenient and decentralized way to participate in Alphabet's stock movements without direct ownership. This token is a bridge between traditional finance and the world of digital assets, offering a unique investment opportunity.",
    certi_photo: google_certi,
    certi_right: "IndexX Google Stock Certificate is a digital asset that accurately tracks the real-time performance of Alphabet Inc. (GOOGL) stock on the blockchain. It provides investors with a convenient and decentralized way to participate in Alphabet's stock movements without direct ownership. This token is a bridge between traditional finance and the world of digital assets, offering a unique investment opportunity.",
  },
  {
    id: "3",
    name: "Pepsico",
    symbol: "PEP",
    tier: "High",
    value: "0.17110",
    shop: "",
    logo: pepsi_logo,
    tokenName: "PepsiCo Stock",
    companyName: "PepsiCo",
    token_photo: pepsi_token,
    text_left_top: "PepsiCo, Inc.",
    text_left: "PepsiCo, Inc. (PEP) stock represents ownership in a global food and beverage powerhouse. The company's portfolio includes iconic brands like Pepsi, Frito-Lay, and Quaker Oats. PEP stock is sought after by investors for its consistent dividend payments and stability in the consumer goods sector, making it a staple in many investment portfolios.",
    text_right: "IndexX PEP Stock Token is a digital asset designed to mirror the real-time performance of PepsiCo, Inc. (PEP) stock on the blockchain. It allows investors to gain exposure to PEP's market movements without owning the actual shares, providing a decentralized and efficient way to engage with the consumer goods giant's performance. This token bridges traditional finance with the world of digital assets, offering a unique investment avenue.",
    certi_photo: pepsi_certi,
    certi_right: "IndexX PepsiCo Stock Certificate is a digital asset designed to mirror the real-time performance of PepsiCo, Inc. (PEP) stock on the blockchain. It allows investors to gain exposure to PepsiCo's market movements without owning the actual shares, providing a decentralized and efficient way to engage with the consumer goods giant's performance. This token bridges traditional finance with the world of digital assets, offering a unique investment avenue.",
  },
  {
    id: "4",
    name: "Microsoft",
    symbol: "MSFT",
    tier: "High",
    value: "0.31026",
    shop: "",
    logo: msft_logo,
    tokenName: "Microsoft Corp Stock",
    companyName: "Microsoft",
    token_photo: msft_token,
    text_left_top: "Microsoft Corp.",
    text_left: "Microsoft Corporation (MSFT) stock represents ownership in one of the world's leading technology companies. Known for its software products like Windows and Office, as well as cloud computing services through Azure, MSFT has a diversified portfolio. MSFT stock is a popular choice for investors due to its consistent growth and dividend payments.",
    text_right: "IndexX MSFT Stock Token is a digital asset designed to closely mimic the real-time performance of Microsoft Corporation (MSFT) stock on the blockchain. It enables investors to participate in MSFT's market movements without owning the actual shares, offering a decentralized and streamlined method to engage with the tech giant's performance. This token bridges traditional finance with the realm of digital assets, presenting a unique investment opportunity.",
    certi_photo: msft_certi,
    certi_right: "Indexx Microsoft Stock Certificate is a digital asset designed to closely mimic the real-time performance of Microsoft Corporation (MSFT) stock on the blockchain. It enables investors to participate in Microsoft 's market movements without owning the actual shares, offering a decentralized and streamlined method to engage with the tech giant's performance. This token bridges traditional finance with the realm of digital assets, presenting a unique investment opportunity.",
  },
  {
    id: "5",
    name: "Amazon",
    symbol: "AMZN",
    tier: "High",
    value: "0.13138",
    shop: "",
    logo: amzn_logo,
    tokenName: "Amazon Stock",
    companyName: "Amazon",
    token_photo: amzn_token,
    text_left_top: "Amazon.com, Inc.",
    text_left: "Amazon.com, Inc. (AMZN) is a global e-commerce and technology giant. Renowned for its vast online marketplace and cloud computing services through Amazon Web Services (AWS), AMZN stock is highly coveted by investors. Its consistent growth and innovations in various sectors make it a cornerstone of many diversified investment portfolios.",
    text_right: "IndexX AMZN Stock Token is a blockchain-based digital asset designed to accurately track the real-time performance of Amazon.com Inc. (AMZN) stock. It offers investors a decentralized and efficient way to participate in Amazon's stock movements without the need to own the actual shares. This token combines the benefits of traditional finance with the convenience of digital assets, providing a unique investment opportunity.",
    certi_photo: amzn_certi,
    certi_right: "IndexX Amazon Stock Certificate is a blockchain-based digital asset designed to accurately track the real-time performance of Amazon.com Inc. (AMZN) stock. It offers investors a decentralized and efficient way to participate in Amazon's stock movements without the need to own the actual shares. This token combines the benefits of traditional finance with the convenience of digital assets, providing a unique investment opportunity.",
  },
  {
    id: "6",
    name: "Nvidia",
    symbol: "NVDA",
    tier: "High",
    value: "0.4192",
    shop: "",
    logo: nvidia_logo,
    tokenName: "NVIDIA Stock",
    companyName: "NVIDIA",
    token_photo: nvid_token,
    text_left_top: "NVIDIA Corp.",
    text_left: "NVIDIA Corporation (NVDA) is a leading global technology company known for its cutting-edge graphics processing units (GPUs) and AI-focused hardware. NVDA stock is highly sought after by investors due to its pivotal role in AI, gaming, and data center markets. It remains a prominent choice for tech-oriented investment portfolios.",
    text_right: "IndexX NVDA Stock Token is a blockchain-based digital asset meticulously mirroring the real-time performance of NVIDIA Corporation (NVDA) stock. It empowers investors to engage in NVDA's market dynamics without direct share ownership, offering a decentralized and streamlined approach to participating in the semiconductor giant's performance. This token blends traditional finance with the realm of digital assets, delivering an innovative investment solution.",
    certi_photo: nvid_certi,
    certi_right: "IndexX NVIDIA Stock Certificate is a blockchain-based digital asset meticulously mirroring the real-time performance of NVIDIA Corporation (NVDA) stock. It empowers investors to engage in NVDA's market dynamics without direct share ownership, offering a decentralized and streamlined approach to participating in the semiconductor giant's performance. This token blends traditional finance with the realm of digital assets, delivering an innovative investment solution.",
  },
  {
    id: "7",
    name: "Apple Inc",
    symbol: "AAPL",
    tier: "High",
    value: "0.17225",
    shop: "",
    logo: apple_logo,
    tokenName: "Apple Stock",
    companyName: "Apple Inc",
    token_photo: apple_token,
    text_left_top: "Apple Inc. (AAPL)",
    text_left: "is a global technology giant known for its iconic products like the iPhone and Mac computers. With a commitment to innovation, Apple has built a strong ecosystem of hardware, software, and services. AAPL stock is a staple in investment portfolios, reflecting Apple's enduring influence in the tech world.",
    text_right: "is a digital asset on the blockchain that closely tracks the performance of Apple Inc. (APPL) stock in real-time. 1 APPL Stock token is equal to 1000 Indexx Stock Token. By holding this token, investors can participate in the fluctuations of Apple's stock price without owning the actual shares. It offers a seamless way to engage with the financial markets through decentralized finance (DeFi) while following the fortunes of one of the world's leading technology companies, renowned for its iconic consumer electronics and software innovations.",
    certi_photo: apple_certi,
    certi_right: "is a digital asset on the blockchain that closely tracks the performance of Apple Inc. (APPL) stock in real-time. By holding this token, investors can participate in the fluctuations of Apple's stock price without owning the actual shares. It offers a seamless way to engage with the financial markets through decentralized finance (DeFi) while following the fortunes of one of the world's leading technology companies, renowned for its iconic consumer electronics and software innovations.",
  },
  {
    id: "8",
    name: "Broadcom",
    symbol: "BCM",
    tier: "High",
    value: "0.81348",
    shop: "",
    logo: bcm_logo,
    tokenName: "Broadcom Inc Stock",
    companyName: "Broadcom",
    token_photo: broadcom_token,
    text_left_top: "Broadcom Inc",
    text_left: "Broadcom Inc. (AVGO) is a global semiconductor and infrastructure software company. Renowned for its diverse product portfolio, it serves industries like data center, wireless, and broadband. AVGO stock is favored by investors for its consistent growth and dividends, making it a key player in the tech sector and a common choice for tech-focused portfolios.",
    text_right: "IndexX Broadcom Inc. Stock Token is a blockchain-based digital asset designed to accurately mirror the real-time performance of Broadcom Inc. (BCM) stock. It provides investors with a decentralized and efficient way to gain exposure to Broadcom's stock movements without the need to own the actual shares. This token offers a bridge between traditional finance and the world of digital assets, offering an innovative investment opportunity.",
    certi_photo: broadcom_certi,
    certi_right: "IndexX Broadcom Inc. Stock Certificate is a blockchain-based digital asset designed to accurately mirror the real-time performance of Broadcom Inc. (BCM) stock. It provides investors with a decentralized and efficient way to gain exposure to Broadcom's stock movements without the need to own the actual shares. This token offers a bridge between traditional finance and the world of digital assets, offering an innovative investment opportunity.",
  },
  {
    id: "9",
    name: "Meta",
    symbol: "META",
    tier: "High",
    value: "0.29837",
    shop: "",
    logo: meta_logo,
    tokenName: "Meta Stock",
    companyName: "Meta",
    token_photo: meta_token,
    text_left_top: "Meta Platforms Inc",
    text_left: "Meta Platforms, Inc. (formerly Facebook, Inc.) is a leading social media and technology conglomerate. It operates platforms like Facebook, Instagram, WhatsApp, and Oculus VR. Meta is at the forefront of the metaverse concept, aiming to redefine online social interaction and digital experiences. Its stock, traded under META, reflects its evolving digital vision.",
    text_right: "IndexX Meta Stock Token is a blockchain-based digital asset designed to closely replicate the real-time performance of Meta Platforms, Inc. (META) stock. It offers investors a decentralized and efficient way to participate in Meta's stock movements without the need to own the actual shares. This token merges traditional finance with the realm of digital assets, providing a novel investment opportunity.",
    certi_photo: meta_certi,
    certi_right: "IndexX Meta Stock Certificate is a blockchain-based digital asset designed to closely replicate the real-time performance of Meta Platforms, Inc. (META) stock. It offers investors a decentralized and efficient way to participate in Meta's stock movements without the need to own the actual shares. This token merges traditional finance with the realm of digital assets, providing a novel investment opportunity.",
  },
  {
    id: "10",
    name: "SNP500",
    symbol: "SNP500",
    tier: "High",
    value: "4.32671",
    shop: "",
    logo: sp_logo,
    tokenName: "S&P500 Inc Stock",
    companyName: "S&P500",
    token_photo: sp5_token,
    text_left_top: "S&P500",
    text_left: "The S&P 500, often referred to as just the S&P, is a widely followed stock market index that reflects the performance of 500 large-cap U.S. companies. It serves as a barometer for the overall health of the U.S. stock market and is considered a benchmark for investors and financial professionals worldwide.",
    text_right: "IndexX S&P 500 Stock Token is a digital asset that tracks the real-time performance of the S&P 500 index. It allows investors to gain exposure to a diversified portfolio of the 500 largest publicly traded companies in the U.S. stock market. This token provides a convenient and efficient way to participate in the broader equity market within the decentralized finance (DeFi) ecosystem.",
    certi_photo: sp5_certi,
    certi_right: "IndexX S&P 500 Stock Certificate is a digital asset that tracks the real-time performance of the S&P 500 index. It allows investors to gain exposure to a diversified portfolio of the 500 largest publicly traded companies in the U.S. stock market. This token provides a convenient and efficient way to participate in the broader equity market within the decentralized finance (DeFi) ecosystem.",
  },
];
