import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
// import Details from './Components/Details/Details';
import StockChart from "./Components/Charts/Chart";
// import CertificateDetails from './Components/CertificateDetails/CertificateDetails';
import HeaderNew from "./Components/Header/HeaderNew";
import ComingSoon from "./Components/ComingSoon/ComingSoon";
import { ThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import NewDetails from "./Components/NewDetails/NewDetails";
import Collections from "./Components/Collections/Collections";
import Certifications from "./Components/Collections/CertificateContainer/Certifications";
import NewCertificateDetails from "./Components/NewCertificateDetails/NewCertificateDetails";
import Footer from "./Components/Footer/Footer";
import ETFs from "./Components/Collections/ETFContainer/ETFs";
import ETFDetails from "./Components/ETFDetails/ETFDetails";
import LearnCertificate from "./Components/NewCertificateDetails/LearnCertificate";
import LearnETF from "./Components/Collections/ETFContainer/LearnETF";
import LearnToken from "./Components/NewDetails/LearnToken";
import HeaderTop from "./Components/HeaderTop";
import { decodeJWT, loginWithToken } from "./services/api";
import { useEffect } from "react";

function App() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const params = new URLSearchParams(window.location.search);
  const defaultSignInToken = params.get('signInToken');

  useEffect(() => {
    const redirectFlag = localStorage.getItem('redirected');
    if (defaultSignInToken && !redirectFlag) {
      checkLogin(defaultSignInToken);
    }
  }, [defaultSignInToken]);

  async function checkLogin(defaultSignInToken) {
    try {
      const res = await loginWithToken(defaultSignInToken);
      if (res.status === 200) {
        let resObj = await decodeJWT(res.data.access_token);
        localStorage.setItem('email', resObj?.email);
        localStorage.setItem('user', resObj?.email);
        localStorage.setItem('access_token', resObj?.access_token);
        localStorage.setItem('refresh_token', resObj?.refresh_token);
        localStorage.setItem('userType', resObj?.userType);
        localStorage.setItem('redirected', 'true'); // Set flag
        window.location.reload();
      } else {
        console.log(res.data);
      }
    } catch (err) {
      console.log('err', err);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          {/* <HeaderNew /> */}
          <HeaderTop />
          <Routes>
            <Route path="/*" element={<Home />} />
            {/* <Route path="/details" element={<Details />} /> */}
            {/* <Route path="/certificates" element={<CertificateDetails />} /> */}
            <Route path="/chart/:symbol" element={<StockChart />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/details" element={<Collections />} />
            <Route path="/view-detail/:symbol" element={<NewDetails />} />
            <Route path="/learn-tokens" element={<LearnToken />} />

            <Route path="/certificates" element={<Certifications />} />
            <Route path="/learn-certificates" element={<LearnCertificate />} />
            <Route
              path="/view-certificate/:symbol"
              element={<NewCertificateDetails />}
            />

            <Route path="/etf" element={<ETFs />} />
            <Route path="/learn-etfs" element={<LearnETF />} />
            <Route path="/view-etf/:symbol" element={<ETFDetails />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
