import React, {useState, useEffect} from "react";
import img1 from "../../assets/arts/how_to_token/1.svg";
import img2 from "../../assets/arts/how_to_token/2.svg";
import img3 from "../../assets/arts/how_to_token/3.svg";
import img4 from "../../assets/arts/how_to_token/4.svg";
import img5 from "../../assets/arts/how_to_token/5.svg";
import img6 from "../../assets/arts/how_to_token/6.svg";
import img7 from "../../assets/arts/how_to_token/7.svg";

import img1_light from "../../assets/arts/how_to_token/light/1.svg";
import img2_light from "../../assets/arts/how_to_token/light/2.svg";
import img3_light from "../../assets/arts/how_to_token/light/3.svg";
import img4_light from "../../assets/arts/how_to_token/light/4.svg";
import img5_light from "../../assets/arts/how_to_token/light/5.svg";
import img6_light from "../../assets/arts/how_to_token/light/6.svg";
import img7_light from "../../assets/arts/how_to_token/light/7.svg";

import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { baseCEXURL } from "../../services/api";

const HowtoBuyToken = () => {

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <Typography fontSize={"60px"} textAlign="center">
        How to Buy Stock Token
      </Typography>
      <Box className="d-flex justify-content-center" mt={10} mb={15}>
        <Link
          to="/details?type=all"
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            1. Select Stock Token
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img1 : img1_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
          2. Inside Exchange, select Wall Street  from the iconic header
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img2 : img2_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
          3. Enter desired amount and click Preview Purchase
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img3 : img3_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            4. Click Confirm Purchase
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img4 : img4_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left" minWidth={"656px"}>
            5. Log in to your PayPal account and complete the payment process
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img5 : img5_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            6. Purchase in Progress. Click to Go to Wallet
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img6 : img6_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell/wallet`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            7. Inside Wallet, you will see the asset you just purchased
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img7 : img7_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
    </div>
  );
};

export default HowtoBuyToken;
