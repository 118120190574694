import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ETFData } from "../Collections/ETFData";
import "./ETFComponentsTable.css";

const ETFComponentsTable = ({ symbol, data }) => {
  const [sorted, setsorted] = useState([]);
  const [allInfo, setAllInfo] = useState([]);
  // const [fdata, setFdata] = useState([]);
  // const [collData, setCollData] = useState([]);
  // const [fullData, setFullData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const allDetails = ETFData.filter((item) => item.symbol === symbol)[0];
    if (allDetails && data?.allStockPrice) {
      const updatedComponents = allDetails.components.map(component => {
        const stockPriceInfo = data.allStockPrice.find(stock => stock.symbol === component.symbol);
        return {
          ...component,
          market_value: stockPriceInfo?.price || component.market_value,
          percentage_value: stockPriceInfo?.weightedPrice || component.percentage_value
        };
      });
      setAllInfo({ ...allDetails, components: updatedComponents });
      setsorted(updatedComponents);
    }
  }, [symbol, data]);

  return (
    <TableContainer
      className="etf_table"
      component={Paper}
      style={{ maxWidth: "100%", overflowX: "auto", marginTop: "15px" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{allInfo?.column_name}</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>Sector</TableCell>
            <TableCell>Asset Class</TableCell>
            <TableCell>Market Value</TableCell>
            <TableCell>Weight(%)</TableCell>
            <TableCell>Percentage Value</TableCell>
            {/* <TableCell>Shares</TableCell> */}
            <TableCell>Market Currency</TableCell>
            {/* <TableCell align={isMobile ? 'left' : 'right'} sx={{pr:isMobile ? 0 : 13}}>Exchange</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted.map((collection) => (
            <TableRow
              key={collection.id}
              className="row-link"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <TableCell style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "center",
                    gap: isMobile ? 0.8 : 2,
                  }}
                >
                  <Box
                  // width={isMobile ? "15px" : "60px"}
                  >
                    <img
                      src={collection.image}
                      alt="no-stock"
                      style={{
                        width: `${isMobile
                          ? "15px"
                          : collection?.type.includes("Stock")
                            ? "70px"
                            : "35px"
                          }`,
                      }}
                    />
                  </Box>
                  <Box sx={{ alignSelf: "center" }}>{collection.name}</Box>
                </Box>
              </TableCell>

              <TableCell style={{ textDecoration: "none" }}>
                {collection.symbol}
              </TableCell>
              <TableCell style={{ textDecoration: "none" }}>
                {collection.sector}
              </TableCell>
              <TableCell style={{ textDecoration: "none" }}>
                {collection.asset_class}
              </TableCell>
              <TableCell style={{ textDecoration: "none" }}>
                $ {Math.floor(collection.market_value * 10000) / 10000}{" "}
                <span
                  style={{
                    fontSize: `${isMobile ? "6px" : "10px"}`,
                    opacity: "0.9",
                  }}
                >
                  USD
                </span>
              </TableCell>
              <TableCell style={{ textDecoration: "none" }}>
                {collection.percentage}%
              </TableCell>
              <TableCell style={{ textDecoration: "none" }}>
                $ {Math.floor(collection.percentage_value * 10000) / 10000}{" "}
                <span
                  style={{
                    fontSize: `${isMobile ? "6px" : "10px"}`,
                    opacity: "0.9",
                  }}
                >
                  USD
                </span>
              </TableCell>
              {/* <TableCell style={{ textDecoration: 'none' }}>
                {collection.shares}
              </TableCell> */}
              <TableCell style={{ textDecoration: "none" }}>
                {collection.market_currency}
              </TableCell>
              {/* <TableCell align={isMobile ? 'left' : 'right'} >
              <Button
                        variant='contained'
                        disableTouchRipple
                        component={Link}
                        to={baseCEXURL}
                        sx={{
                            backgroundColor: "#545454",
                            borderRadius: "4px",
                            px: isMobile ? 2 : 6,
                            textTransform: "none",
                            fontSize: `${isMobile ? "10px" : "14px"}`,
                            fontWeight: 400,
                            boxShadow: "none",
                            "&:hover": {
                            backgroundColor: "#303030",
                            boxShadow: "none",
                            },
                        }}
                        >
                          Buy
                        </Button>
              
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ETFComponentsTable;
