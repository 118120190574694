import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import './LearnETF.css'

const accordionData = [
  {
    id: 1,
    question:"What makes Indexx ETFs different from traditional ETFs?",
    answer:"Indexx ETFs are packaged tokens pegged to real tokens with the ability to stake, providing a unique blend of traditional ETF features and the benefits of cryptocurrencies.",
  },
  {
    id: 2,
    question:"Can I stake Indexx ETFs, and how does this work?",
    answer:"Yes, you can stake Indexx ETFs on our platform, allowing you to earn additional rewards by participating in staking programs.",
  },
  {
    id: 3,
    question:"How does trading Indexx ETFs differ from trading traditional ETFs?",
    answer:"Indexx ETFs are tradable with other cryptocurrencies, stocks, and tokens, offering more trading flexibility compared to traditional ETFs typically confined to traditional markets.",
  },
  {
    id: 4,
    question:"What are the benefits of the global accessibility of Indexx ETFs?",
    answer:"Indexx ETFs are accessible globally without traditional market restrictions, providing faster and more accessible international transactions compared to traditional ETFs.",
  },
  {
    id:5,
    question:"Can I trade Indexx ETFs with other financial instruments?",
    answer:"Yes, Indexx ETFs can be traded with other cryptocurrencies, stocks, and tokens. Traditional ETFs have limited interoperability with other financial instruments.",
  },
  {
    id:6,
    question:"Is there community involvement in the governance of Indexx ETFs?",
    answer:"Yes, there is potential for community involvement and governance in Indexx ETFs, providing users with a say in decision-making processes.",
  },
  {
    id:7,
    question:"How does the decentralized nature of Indexx ETFs benefit investors?",
    answer:"The decentralized nature of Indexx ETFs, facilitated by blockchain technology, brings benefits such as increased security, transparency, and potential community involvement in governance.",
  },
  {
    id:8,
    question:"What is the asset composition of Indexx ETFs?",
    answer:"Indexx ETFs are pegged to real tokens and represent a diverse portfolio that mirrors an index. This composition provides exposure to various assets within the cryptocurrency space.",
  },
  {
    id:9,
    question:"Are Indexx ETFs available for trading 24/7?",
    answer:"Yes, trading of Indexx ETFs is available 24/7, providing constant market access compared to traditional ETFs that operate within fixed market hours.",
  },
  {
    id:10,
    question:"How are Indexx ETFs issued?",
    answer:"Indexx ETFs are managed and issued by the Indexx platform, offering a digital representation of diverse portfolios on the blockchain.",
  },

];


const ETFFAQ = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedAccordion, setExpandedAccordion] = React.useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <div>
      <Box width={"80%"} mx={"auto"} pt={2}>
      {accordionData.map((item) => (
        <Accordion
          defaultExpanded
          disableGutters
          sx={{
            backgroundColor: "var(--background)",
            color: "var(--color-text)",
            boxShadow:"none",
            border:"none",
          }}
          key={item.id}
          expanded={expandedAccordion === item.id}
          onChange={handleAccordionChange(item.id)}
        >
          <AccordionSummary
            sx={{
              color: "var(--color-text)",
              mb:0
            }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            
          >
            {expandedAccordion === item.id ? 
                <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretDown color="var(--color-text)" />
                </Typography>
             :
            <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretRight color="var(--color-text)" />
             </Typography>
            }

            <Typography variant="subtitle1" fontSize={"25px"} fontWeight={100} textAlign={"left"}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                color: "var(--color-text)",
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
                pt:0,
                pb:5
              }}
              ml={4}
            >
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default ETFFAQ;
