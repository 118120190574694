import amzn from "../../assets/token_images/amazon.png";
import tesla from "../../assets/token_images/Tesla.png";
import apple from "../../assets/token_images/apple.png";
import broadcom from "../../assets/token_images/BCM.png";
import google from "../../assets/token_images/google.png";
import msft from "../../assets/token_images/microsoft.png";
import nvid from "../../assets/token_images/nvidia.png";
import meta from "../../assets/token_images/meta.png";
import pepsi from "../../assets/token_images/pepsi.png";
import sp5 from "../../assets/token_images/sp500.png";

import amzn_certi from "../../assets/certificates/Amazon_.png";
import tesla_certi from "../../assets/certificates/tsla.png";
import apple_certi from "../../assets/certificates/appl.png";
import broadcom_certi from "../../assets/certificates/bcm.png";
import google_certi from "../../assets/certificates/google.png";
import msft_certi from "../../assets/certificates/msft.png";
import nvid_certi from "../../assets/certificates/nvda.png";
import meta_certi from "../../assets/certificates/Meta.png";
import pepsi_certi from "../../assets/certificates/pepsi.png";
import sp5_certi from "../../assets/certificates/P&P500.png";

import altcry from "../../assets/ETF/alphacrypto.png";
import bltok from "../../assets/ETF/token blend.png";
import cryx10 from "../../assets/ETF/cryptocap.png";
import eqstk from "../../assets/ETF/EqStocks.png";
import indxxf from "../../assets/ETF/indxfocus.png";

import logo from "../../assets/Wall street Logo.png";
import token from "../../assets/stock token icon white.png";
import token_light from "../../assets/stock token icon.png";
import certific from "../../assets/cert icon white.png";
import certific_light from "../../assets/cert icon.png";
import etf from "../../assets/etf logo white.png";
import etf_light from "../../assets/etf logo.png";
import people from "../../assets/heads.png";
import fortune from "../../assets/lady toss.png";

import { Grid, Typography, Box, Button, useMediaQuery } from "@mui/material";

import { Link } from "react-router-dom";
// import { useEffect, useState } from "react";
import "./Home.css";
import Card from "./Card";
import SubHeader from "../SubHeader/SubHeader";
import { useTheme } from '@emotion/react';
import { useEffect, useState } from "react";

const Home = () => {

  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down('md'));

  const tokencards = [
    {
      id: "1",
      name: "Tesla",
      symbol: "TSLA",
      image: tesla,
      link: "/view-detail/TSLA",
      learn: "",
    },
    {
      id: "2",
      name: "Google",
      symbol: "GOOGL",
      image: google,
      link: "/view-detail/GOOGL",
      learn: "",
    },
    {
      id: "3",
      name: "Pepsico",
      symbol: "PEP",
      image: pepsi,
      link: "/view-detail/PEP",
      learn: "",
    },
    {
      id: "4",
      name: "Microsoft",
      symbol: "MSFT",
      image: msft,
      link: "/view-detail/MSFT",
      learn: "",
    },
    {
      id: "5",
      name: "Amazon",
      symbol: "AMZN",
      image: amzn,
      link: "/view-detail/AMZN",
      learn: "",
    },
    {
      id: "6",
      name: "Nvidia",
      symbol: "NVDA",
      image: nvid,
      link: "/view-detail/NVDA",
      learn: "",
    },
    {
      id: "7",
      name: "Apple",
      symbol: "AAPL",
      image: apple,
      link: "/view-detail/AAPL",
      learn: "",
    },
    {
      id: "8",
      name: "Broadcom",
      symbol: "BCM",
      image: broadcom,
      link: "/view-detail/BCM",
      learn: "",
    },
    {
      id: "9",
      name: "Meta",
      symbol: "META",
      image: meta,
      link: "/view-detail/META",
      learn: "",
    },
    {
      id: "10",
      name: "S&P",
      image: sp5,
      link: "/view-detail/S&P500",
      learn: "",
    },
  ];

  const certificates = [
    {
      id: "1",
      name: "Tesla",
      symbol: "TSLA",
      image: tesla_certi,
      link: "/view-certificate/TSLA",
      learn: "",
    },
    {
      id: "2",
      name: "Google",
      symbol: "GOOGL",
      image: google_certi,
      link: "/view-certificate/GOOGL",
      learn: "",
    },
    {
      id: "3",
      name: "Pepsico",
      symbol: "PEP",
      image: pepsi_certi,
      link: "/view-certificate/PEP",
      learn: "",
    },
    {
      id: "4",
      name: "Microsoft",
      symbol: "MSFT",
      image: msft_certi,
      link: "/view-certificate/MSFT",
      learn: "",
    },
    {
      id: "5",
      name: "Amazon",
      symbol: "AMZN",
      image: amzn_certi,
      link: "/view-certificate/AMZN",
      learn: "",
    },
    {
      id: "6",
      name: "Nvidia",
      symbol: "NVDA",
      image: nvid_certi,
      link: "/view-certificate/NVDA",
      learn: "",
    },
    {
      id: "7",
      name: "Apple",
      symbol: "AAPL",
      image: apple_certi,
      link: "/view-certificate/AAPL",
      learn: "",
    },
    {
      id: "8",
      name: "Broadcom",
      symbol: "BCM",
      image: broadcom_certi,
      link: "/view-certificate/BCM",
      learn: "",
    },
    {
      id: "9",
      name: "Meta",
      symbol: "META",
      image: meta_certi,
      link: "/view-certificate/META",
      learn: "",
    },
    {
      id: "10",
      name: "S&P",
      image: sp5_certi,
      link: "/view-certificate/SNP500",
      learn: "",
    },
  ];

  const etfs = [
    {
      id: "1",
      name: "EquiStocks ETF",
      symbol: "EQSTK",
      image: eqstk,
      link: "/view-etf/EQSTK",
      learn: "",
    },
    {
      id: "2",
      name: "CryptoCap 10 ETF",
      symbol: "CRYX10",
      image: cryx10,
      link: "/view-etf/CRYX10",
      learn: "",
    },
    {
      id: "3",
      name: "AltCrypto Alpha ETF",
      symbol: "ALTCRY",
      image: altcry,
      link: "/view-etf/ALTCRY",
      learn: "",
    },
    {
      id: "4",
      name: "Indexx Focus ETF",
      symbol: "INDXXF",
      image: indxxf,
      link: "/view-etf/INDXXF",
      learn: "",
    },
    {
      id: "5",
      name: "Balanced Token Blend ETF",
      symbol: "BLTOK",
      image: bltok,
      link: "/view-etf/BLTOK",
      learn: "",
    },
  ];
  // const isMobile = width <= 768;

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <SubHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
          gap: 3,
          pt: 15,
          pb: 8,
        }}
      >
        <div className="home-container">
          <Typography
            variant="text"
            fontSize={isMobile ? "28px" : "66px"}
            fontWeight={500}
            fontStyle={"italic"}
          >
            Indexx Wall Street
          </Typography>
          <img src={logo} alt="logo" width={isMobile ? "241px" : "304px"} />
        </div>

        <Typography
          width={isMobile ? "303px" : "562.1px"}
          fontSize={"13px"}
          fontFamily={"Inter"}
          // fontWeight={100}
          lineHeight={"28px"}
          textAlign={"left"}
          mt={2}
        >
          The Indexx Wall Street online platform redefines how we interact with
          financial insights. Rooted in the legacy of Wall Street's expertise,
          this digital hub brings together traders, investors, and enthusiasts
          from across the globe. Seamlessly blending real-time data with expert
          analysis, Indexx Wall Street offers a dynamic space where market
          trends, investment strategies, and economic developments converge.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 25,
          }}
        >
          <img src={logo} alt="logo" width={isMobile ? "106px" : "154px"} />

          <Typography
            variant="text"
            fontSize={isMobile ? "36px" : "60px"}
            fontWeight={500}
            mb={3}
          >
            Indexx Wall Street
          </Typography>

          <Typography
            width={isMobile ? "302px" : "517.03px"}
            fontSize={"13px"}
            fontFamily={"Inter"}
            // fontWeight={100}
            lineHeight={"28px"}
            textAlign={"left"}
            ml={isMobile ? 0 : 0.6}
          >
            Indexx Wall Street is working like mirro of performance of Wall
            Street ,the stocks on the exchanges, by listing the stock tokens
            mirro to the top 50 stocks without security on indexx Wall Street
            Platform, indexx stock tokens’ value will follow the behaviece of
            the stocks on the exchange of Nasdaq and NYSE. Indexx will purchase
            the real stocks to back the tokens sold to the users and investors,
            users csn trade the tokens on indexx EX and indexx Swap, and can
            cash out to USD from indexx EX at anytime.
          </Typography>
        </Box>

        <img src={people} alt="logo" width={"100%"} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 25,
            mb: 4,
          }}
        >
          <img src={theme === "dark" ? certific : certific_light} alt="logo" width={isMobile ? "60px" : "auto"} />

          <Typography
            variant="text"
            fontSize={isMobile ? "27px" : "60px"}
            fontWeight={500}
            mb={3}
            mt={1}
          >
            Indexx Stock Certificate
          </Typography>

          <Typography
            width={isMobile ? "302px" : "690.23px"}
            fontSize={"13px"}
            fontFamily={"Inter"}
            // fontWeight={100}
            lineHeight={"28px"}
            textAlign={"left"}
            ml={isMobile ? 0 : 0.6}
          >
            The Indexx Stock Certificate is a legal document that represents
            ownership in the Indexx Corporation. It includes vital information
            such as the unique certificate number, the shareholder's name, the
            number of shares owned, and the issue date. This certificate
            signifies ownership in a specific class of Indexx stock and may
            contain corporate seals and official signatures for authenticity. It
            may also indicate any special restrictions or legends associated
            with the shares. Ownership and transfer details are typically
            recorded electronically for convenience and security.
          </Typography>

          <Button disableTouchRipple>
            <Link
              to="/learn-certificates"
              style={{ textDecoration: "none" }}
            >
              <Typography
                component="span"
                color="var(--color-text)"
                sx={{
                  color: "#006DFF",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                Learn More
              </Typography>
            </Link>
          </Button>
        </Box>
        <Box width={"72%"}>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 1, sm: 12, md: 12 }}
          >
            {certificates.map((item, id) => (
              <Card details={item} id={id} />
            ))}
          </Grid>
        </Box>
        <Box
          // className="buy-parent"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Button
            sx={{
              backgroundColor: "#454545",
              color:"#efefef",
              borderRadius: "0",
              px: 4,
              py: 1.5,
              width: `${isMobile ? "250px" : "400px"}`,
              zIndex: "5",
              "&:hover": {
                background: "#252525",
              },
            }}
          >
            <Link
              to="/certificates?type=all"
              style={{ textDecoration: "none" }}
            >
              <Typography
                component="span"
                color="#e0e0e0"
                sx={{ textTransform: "none" }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                See more Stock Certificate
              </Typography>
            </Link>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 25,
            mb: 4,
          }}
        >
          <img src={theme === "dark" ? token : token_light} alt="logo" width={isMobile ? "60px" : "auto"} />

          <Typography
            variant="text"
            fontSize={isMobile ? "33px" : "60px"}
            fontWeight={500}
            mb={3}
            mt={2}
          >
            Indexx Stock Token
          </Typography>

          <Typography
            width={isMobile ? "302px" : "556px"}
            fontSize={"13px"}
            fontFamily={"Inter"}
            // fontWeight={100}
            lineHeight={"28px"}
            textAlign={"left"}
            ml={isMobile ? 0 : 0.6}
          >
            Indexx stock tokens working like mirro of performance of each
            individual stock of the exchange in Wall Street, they do not have
            the stakes of stocks but follow the ups and downs of the stock
            market, indexx develop the top 50 stock tokens to follow or mirro
            the top 50 stocks of the market on the exchange of Nasdaq and NYSE.
            indexx Wall Street Platform, indexx stock tokens’ value will reflect
            the behaviece of the stocks on the exchange of Nasdaq and NYSE.
            Indexx will purchase the real stocks to back the tokens sold to the
            users and investors, users csn trade the tokens on indexx EX and
            indexx Swap, and can cash out to USD from indexx EX at anytime.
          </Typography>

          <Button disableTouchRipple>
            <Link to="/learn-tokens" style={{ textDecoration: "none" }}>
              <Typography
                component="span"
                color="var(--color-text)"
                sx={{
                  color: "#006DFF",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                Learn More
              </Typography>
            </Link>
          </Button>
        </Box>
        <Box width={"72%"}>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 1, sm: 12, md: 12 }}
          >
            {tokencards.map((item, id) => (
              <Card details={item} id={id} />
            ))}
          </Grid>
        </Box>

        <Box
          // className="buy-parent"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Button
            sx={{
              backgroundColor: "#454545",
              color:"#efefef",
              borderRadius: "0",
              px: 4,
              py: 1.5,
              width: `${isMobile ? "250px" : "400px"}`,
              zIndex: "5",
              "&:hover": {
                background: "#252525",
              },
            }}
          >
            <Link to="/details?type=all" style={{ textDecoration: "none" }}>
              <Typography
                component="span"
                color="#e0e0e0"
                sx={{ textTransform: "none" }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                See more Stock Tokens
              </Typography>
            </Link>
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 25,
            mb: 4,
          }}
        >
          <img src={theme === "dark" ? etf : etf_light} alt="logo" width={isMobile ? "60px" : "auto"} />

          <Typography
            width={isMobile ? "302px" : "556px"}
            fontSize={"13px"}
            fontFamily={"Inter"}
            // fontWeight={100}
            lineHeight={"28px"}
            textAlign={"left"}
            ml={isMobile ? 0 : 0.6}
            mt={2}
          >
            The "Indexx ETF" is a cutting-edge exchange-traded fund (ETF)
            designed to provide investors with exposure to a diverse range of
            assets, typically through tokenized representation on blockchain
            technology. It differs from traditional ETFs by utilizing blockchain
            to efficiently and transparently manage and track the underlying
            assets, which can include stocks, cryptocurrencies, or other
            financial instruments. Indexx ETF aims to offer a novel and secure
            way for investors to access and manage their investment portfolios
            while leveraging the benefits of blockchain technology, such as
            increased liquidity, reduced fees, and improved transparency. This
            innovative approach is reshaping the landscape of modern asset
            management.
          </Typography>

          <Button disableTouchRipple>
            <Link to="/learn-etfs" style={{ textDecoration: "none" }}>
              <Typography
                component="span"
                color="var(--color-text)"
                sx={{
                  color: "#006DFF",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                Learn More
              </Typography>
            </Link>
          </Button>
        </Box>
        <Box width={"72%"}>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 1, sm: 12, md: 12 }}
          >
            {etfs.map((item, id) => (
              <Card details={item} id={id} />
            ))}
          </Grid>
        </Box>

        <Box
          // className="buy-parent"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <Button
            sx={{
              backgroundColor: "#454545",
              color:"#efefef",
              borderRadius: "0",
              px: 4,
              py: 1.5,
              width: `${isMobile ? "250px" : "400px"}`,
              zIndex: "5",
              "&:hover": {
                background: "#252525",
              },
            }}
          >
            <Link to="/etf" style={{ textDecoration: "none" }}>
              <Typography
                component="span"
                color="#e0e0e0"
                sx={{ textTransform: "none" }}
                fontWeight={100}
                fontFamily={"Inter"}
                fontSize={"13px"}
              >
                Buy ETFs
              </Typography>
            </Link>
          </Button>
        </Box>

        <Box mt={10}>
          <img src={fortune} alt="person" width={"100%"} />
        </Box>
      </Box>
    </>
  );
};

export default Home;
