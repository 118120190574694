import React from "react";
import {
  Box,
  Button,
  Grid,
  Grow,
  Typography,
} from "@mui/material";
import "./Card.css";
import { HashLink } from "react-router-hash-link";

const Card = ({ details, id }) => {

  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
    // {...(true ? { timeout: 1000 + parsedId } : {})}
    >
      <Grid className="collectionCard" item xs={1} sm={6} md={4}>
        <div className="collectionCard">
          <Box className="cardBody">
            <HashLink to={details.link} style={{ textDecoration: "none" }}>
            <Box style={{ position: "relative" }}>
              <img
                className="imageStyle"
                src={details?.image}
                alt={details?.name}
              />
            </Box>
            {/* </Box> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mt: 1.5,
              }}
            >
              <Button disableTouchRipple>
                  <Typography
                    component="span"
                    color="var(--color-text)"
                    sx={{
                      textTransform: "none",
                      "&:hover": {
                        color: "#006DFF",
                        textDecoration: "underline ",
                      },
                    }}
                    fontWeight={100}
                    fontFamily={"Inter"}
                    fontSize={"13px"}
                  >
                    Learn More
                  </Typography>
              </Button>
            </Box>
            </HashLink>
          </Box>
        </div>
      </Grid>
    </Grow>
  );
};

export default Card;
