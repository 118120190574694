import React, {useState, useEffect} from "react";

import img1 from "../../../assets/arts/How_to_etf/1.svg";
import img2 from "../../../assets/arts/How_to_etf/2.svg";
import img3 from "../../../assets/arts/How_to_etf/3.svg";
import img4 from "../../../assets/arts/How_to_etf/4.svg";
import img5 from "../../../assets/arts/How_to_etf/5.svg";
import img6 from "../../../assets/arts/How_to_etf/6.svg";
import img7 from "../../../assets/arts/How_to_etf/7.svg";
import img8 from "../../../assets/arts/How_to_etf/8.svg";
import img9 from "../../../assets/arts/How_to_etf/9.svg";

import img1_light from "../../../assets/arts/How_to_etf/light/1.svg";
import img2_light from "../../../assets/arts/How_to_etf/light/2.svg";
import img3_light from "../../../assets/arts/How_to_etf/light/3.svg";
import img4_light from "../../../assets/arts/How_to_etf/light/4.svg";
import img5_light from "../../../assets/arts/How_to_etf/light/5.svg";
import img6_light from "../../../assets/arts/How_to_etf/light/6.svg";
import img7_light from "../../../assets/arts/How_to_etf/light/7.svg";
import img8_light from "../../../assets/arts/How_to_etf/light/8.svg";
import img9_light from "../../../assets/arts/How_to_etf/light/9.svg";

import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { baseCEXURL } from "../../../services/api";

const HowtoBuyETF = () => {

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <Typography fontSize={"60px"} textAlign="center">
        How to Buy ETF
      </Typography>
      <Box className="d-flex justify-content-center" mt={10} mb={15}>
        <Link
          to="/etf"
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            1. Select ETF
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img1 : img1_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to="/view-etf/EQSTK"
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
          2. Look at components and click buy button to redirect to the Exchange
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img2 : img2_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15}>
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
          3. Inside  Exchange,  select Wall Street from the iconic header
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img3 : img3_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            4. Select ETF from the iconic header
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img4 : img4_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left" >
            5. Enter amount you want to purchase and click preview purchase
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img5 : img5_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            6. Click confirm purchase
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img6 : img6_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left" minWidth={"656px"}>
            7. Log in to your PayPal account and complete the payment process
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img7 : img7_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            8. Purchase in Progress. Click to Go to Wallet
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img8 : img8_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
      <Box className="d-flex justify-content-center" my={15} >
        <Link
          to={`${baseCEXURL}/indexx-exchange/buy-sell/wallet`}
          style={{ textDecoration: "none", color: "var(--color-text)" }}
        >
          <Typography fontSize={"18px"} textAlign="left">
            9. Inside Wallet, you will see the asset you just purchased
          </Typography>
          <Box component={"img"} src={theme === "dark" ? img9 : img9_light} alt="logo" mt={4} className="step-img"/>
        </Link>
      </Box>
    </div>
  );
};

export default HowtoBuyETF;
