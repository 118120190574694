import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { baseCEXURL, marketsData } from "../../services/api";

import { Link, useParams } from "react-router-dom";
import { ETFData } from "../Collections/ETFData";
import SubHeader from "../SubHeader/SubHeader";
// import { useTheme } from "@emotion/react";
import ETFComponentsTable from "./ETFComponentsTable";
import dark_loader from '../../assets/arts/loader/black.gif';
import loader from '../../assets/arts/loader/wtite.gif';

const ETFDetails = () => {
  const { symbol } = useParams();

   // Initialize etfDetails with the default details from ETFData
   const initialDetails = ETFData.filter((item) => item.symbol === symbol)[0] || {};
   const [etfDetails, setEtfDetails] = useState(initialDetails);
   const [marketData, setMarketData] = useState();
   const [loading, setLoading] = useState(true);

   useEffect(() => {

     const fetchData = async () => {
      try {
        const res = await marketsData();
        const requiredData = res.data.find((x) => x.Symbol === symbol);
  
        if (requiredData) {
          const updatedDetails = {
            ...etfDetails,
            value: parseFloat(requiredData.Price.toFixed(2)), // Round the price
          };
          setEtfDetails(updatedDetails);
        }
  
        setMarketData(requiredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching data (success or error)
      }
    };
  
    fetchData();
   }, [symbol, etfDetails]);

   const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <SubHeader />
      <Box pt={22} pb={5}>
        <Box>
          <Typography
            variant="text"
            component="p"
            fontSize={"20px"}
            fontWeight={200}
            mb={8}
          >
            Gateway to Digital Asset Diversity
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
            mt={2}
          >
            <Box
              style={{
                margin: "20px",
              }}
            >
              <Box component="img" alt="etf_img" src={etfDetails?.logo} />
            </Box>

            <Box
              style={{
                width: "558px",
                display: "flex",
                flexDirection: "column",
                // height: '600px',
                marginBlock: "10px",
                marginInline: "20px",
                textAlign: "left",
                // padding: '20px'
              }}
            >
              <Box className="d-flex justify-content-between">
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  Name
                </Typography>
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  {etfDetails?.name}
                </Typography>
              </Box>
              <Box className="d-flex justify-content-between" mt={2}>
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  Symbol
                </Typography>
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  {etfDetails?.symbol}
                </Typography>
              </Box>
              <Box className="d-flex justify-content-between" mt={2}>
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  Value
                </Typography>
                <Typography
                  variant="text"
                  component="p"
                  fontSize={"35px"}
                  fontWeight={100}
                  textAlign={"justify"}
                >
                  ${etfDetails?.value}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  width: "100%",
                  height: "261.29px",
                  flexWrap: "wrap",
                  alignContent: "end",
                  // alignSelf:"baseline",
                  mt: 4,
                }}
              >
                  
              <Link to={`${baseCEXURL}/update/home/etf-tokens?buyToken=${symbol}`} style={{ textDecoration: "none", width: "100%" }}>
                <Button
                  sx={{
                    backgroundColor: "#454545",
                    borderRadius: "0",
                    px: 4,
                    py: 1.5,
                    width: "100%",
                    zIndex: "5",
                    alignSelf: "baseline",
                    "&:hover": {
                      background: "#252525",
                    },
                  }}
                  disableTouchRipple
                >
                    <Typography
                      component="span"
                      color="#e0e0e0"
                      sx={{ textTransform: "none" }}
                      fontWeight={100}
                      fontFamily={"Inter"}
                      fontSize={"13px"}
                    >
                      Buy
                    </Typography>
                </Button>
                  </Link>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "1260.93px",
            }}
            mx={"auto"}
            mt={6}
          >
            <Typography
              variant="text"
              component="p"
              fontSize={"20px"}
              textAlign={"left"}
              fontWeight={500}
              mb={4}
            >
              {etfDetails.desc}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "1260.93px",
          }}
          mx={"auto"}
          mt={6}
        >
          <Typography
            variant="text"
            component="p"
            fontSize={"35px"}
            textAlign={"left"}
            fontWeight={500}
          >
            Components of {etfDetails?.name}
          </Typography>
          {loading ? (  // Conditionally render the loader
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",  // Adjust the height as needed
          }}
        >
          <img src={theme === "dark" ? dark_loader : loader} alt="" style={{transform:"scale(1.5)"}} />
        </Box>
      ) : (
          <ETFComponentsTable symbol={symbol} data={marketData}/>
      )}
          <Box
            // className="buy-parent"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              mt: 8
            }}
          >

              <Link to={`${baseCEXURL}/update/home/etf-tokens?buyToken=${symbol}`} style={{ textDecoration: "none", width: "45%" }}>
            <Button
              sx={{
                backgroundColor: "#454545",
                borderRadius: "0",
                px: 4,
                py: 1.5,
                width: "100%",
                zIndex: "5",
                "&:hover": {
                  background: "#252525",
                },
              }}
              disableTouchRipple
            >
                <Typography
                  component="span"
                  color="#e0e0e0"
                  sx={{ textTransform: "none" }}
                  fontWeight={100}
                  fontFamily={"Inter"}
                  fontSize={"13px"}
                >
                  Buy
                </Typography>
            </Button>
              </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ETFDetails;
