import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import './LearnToken.css'

const accordionData = [
  {
    id: 1,
    question: "How secure are Indexx stock tokens  on  centralized exchange?",
    answer: "Our centralized exchange employs robust safety measures, including encryption protocols, two-factor authentication (2FA), and regular security audits to safeguard user funds and data.",
  },
  {
    id: 2,
    question: "Can I stake Indexx stock tokens on your platform?",
    answer: "Yes, our platform allows users to stake Indexx stock tokens, providing an opportunity to earn additional rewards.",
  },
  {
    id: 3,
    question: "Is trading limited to other crypto assets only?",
    answer: "No, you can trade Indexx stock tokens with other cryptocurrencies, ETFs, and traditional stocks on our secure and centralized exchange.",
  },
  {
    id: 4,
    question: "How does the centralized nature of your exchange benefit users in terms of safety?",
    answer: "The centralized nature of our exchange allows us to implement advanced safety measures, ensuring a high level of protection for user assets and data.",
  },
  {
    id:5,
    question:"Are there any regulatory compliance measures in place for Indexx stock tokens on your exchange?",
    answer:"Yes, we adhere to regulatory standards to ensure compliance and provide users with a secure and legally compliant trading environment.",
  },
  {
    id:6,
    question:"What happens in the event of a safety breach?",
    answer:"We have a robust incident response plan in place to address and mitigate safety breaches promptly, prioritizing user protection and data integrity.",
  },
  {
    id:7,
    question:"Can I access customer support for safety-related concerns?",
    answer:"Absolutely. Our customer support team is available to assist with any safety-related concerns, providing efficient and responsive assistance to our users.",
  },
];


const TokenFAQ = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedAccordion, setExpandedAccordion] = React.useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <div>
      <Box width={"80%"} mx={"auto"} pt={2}>
      {accordionData.map((item) => (
        <Accordion
          defaultExpanded
          disableGutters
          sx={{
            backgroundColor: "var(--background)",
            color: "var(--color-text)",
            boxShadow:"none",
            border:"none",
          }}
          key={item.id}
          expanded={expandedAccordion === item.id}
          onChange={handleAccordionChange(item.id)}
        >
          <AccordionSummary
            sx={{
              color: "var(--color-text)",
              mb:0
            }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            
          >
            {expandedAccordion === item.id ? 
                <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretDown color="var(--color-text)" />
                </Typography>
             :
            <Typography component="span" color="secondary" lineHeight={"42px"} mr={2}>
                <AiFillCaretRight color="var(--color-text)" />
             </Typography>
            }

            <Typography variant="subtitle1" fontSize={"25px"} fontWeight={100} textAlign={"left"}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                color: "var(--color-text)",
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
                pt:0,
                pb:5
              }}
              ml={4}
            >
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default TokenFAQ;
