import amzn_token from "../../assets/token_images/amazon_1.svg";
import tesla_token from "../../assets/token_images/Tesla-1.svg";
import apple_token from "../../assets/token_images/apple-1.svg";
import broadcom_token from "../../assets/token_images/BCM-.svg";
import google_token from "../../assets/token_images/google-1.svg";
import msft_token from "../../assets/token_images/microsoft-1.svg";
import nvid_token from "../../assets/token_images/nvidia-.svg";
import meta_token from "../../assets/token_images/meta_.svg";
import pepsi_token from "../../assets/token_images/pepsi_.svg";
import sp5_token from "../../assets/token_images/sp500-.svg";

import IN500 from "../../assets/Tokens/IN500.png";
import INEX from "../../assets/Tokens/INEX.png";
import INXC from "../../assets/Tokens/INXC.png";
import Polygon from "../../assets/Tokens/Polygon.png";
import Toncoin from "../../assets/Tokens/Toncoin.png";
import UNUS from "../../assets/Tokens/UNUS SED LEO.png";
import binance from "../../assets/Tokens/binance.png";
import bitcoin from "../../assets/Tokens/bitcoin.png";
import cardano from "../../assets/Tokens/cardano.png";
import chainklink from "../../assets/Tokens/chainklink.png";
import dai from "../../assets/Tokens/Dai.png";
import dogecoin from "../../assets/Tokens/dogecoin.png";
import eth from "../../assets/Tokens/eth.png";
import iUSD from "../../assets/Tokens/iUSD.png";
import litecoin from "../../assets/Tokens/litecoin.png";
import polkadot from "../../assets/Tokens/polkadot.png";
import shiba from "../../assets/Tokens/shiba inu.png";
import solana from "../../assets/Tokens/solana.png";
import usdt from "../../assets/Tokens/usdt.png";
import xrp from "../../assets/Tokens/xrp.png";


import altcry from "../../assets/ETF/alphacrypto.svg";
import bltok from "../../assets/ETF/token_blend.svg";
import cryx10 from "../../assets/ETF/cryptocap.svg";
import eqstk from "../../assets/ETF/EqStocks.svg";
import indxxf from "../../assets/ETF/indxfocus.svg";

import small_altcry from "../../assets/ETF/alcryp.svg";
import small_bltok from "../../assets/ETF/tob.svg";
import small_cryx10 from "../../assets/ETF/cryc10.svg";
import small_eqstk from "../../assets/ETF/eqstk.svg";
import small_indxxf from "../../assets/ETF/indxxf.svg";

export const ETFData = [
  {
    id: "1",
    name: "EqStocks ETF",
    symbol: "EQSTK",
    value: "5000",
    shop: "",
    logo: eqstk,
    small_logo: small_eqstk,
    oneY:2.5,
    threeY:2.5,
    fiveY:2.5,
    incept:5,
    incept_date:"10-Jan-2021",
    perf_date:"31-Oct-2023",
    desc:'The "EqStocks ETF" is an exchange-traded fund that predominantly holds digital tokens representing ownership in a diverse selection of stocks tracked by a specific index. This ETF leverages blockchain technology to provide investors with exposure to the underlying index components through tokenized assets.',
    column_name:"Stock Token",
    components:[
      {
      id:"1",
      name: "Amazon",
      symbol: "AMZN",
      image:amzn_token,
      percentage:"15",
      percentage_value:"0.33",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"2",
      name: "Broadcom",
      symbol: "BCM",
      image:broadcom_token,
      percentage:"15",
      percentage_value:"0.84",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"3",
      name: "Microsoft",
      symbol: "MSFT",
      image:msft_token,
      percentage:"15",
      percentage_value:"0.69",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"4",
      name: "Tesla",
      symbol: "TSLA",
      image:tesla_token,
      percentage:"10",
      percentage_value:"0.243",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"5",
      name: "PepsiCo",
      symbol: "PEP",
      image:pepsi_token,
      percentage:"10",
      percentage_value:"0.763",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"6",
      name: "NVIDIA",
      symbol: "NVDA",
      image:nvid_token,
      percentage:"10",
      percentage_value:"0.357",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"7",
      name: "Apple",
      symbol: "AAPL",
      image:apple_token,
      percentage:"10",
      percentage_value:"0.689",
      buy:"",
      type:"Stock token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    ],
  },
  {
    id: "2",
    name: "CryptoCap 10 ETF",
    symbol: "CRYC10",
    value: "1000",
    shop: "",
    logo: cryx10,
    small_logo: small_cryx10,
    oneY:2.5,
    threeY:2.5,
    fiveY:2.5,
    incept:5,
    incept_date:"10-Jan-2021",
    perf_date:"31-Oct-2023",
    desc:'"CryptoCap 10 ETF" refers to a selection of the ten most prominent and valuable cryptocurrencies in the digital asset market. These tokens include well-known names like Bitcoin, Ethereum, and others, and are typically tracked as a benchmark to gauge the overall performance and trends within the cryptocurrency industry.',
    column_name:"Tokens",
    components:[
      {
      id:"1",
      name: "Bitcoin",
      symbol: "BTC",
      image:bitcoin,
      percentage:"25",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"2",
      name: "Ethereum",
      symbol: "ETH",
      image:eth,
      percentage:"20",
      percentage_value:"0.84",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"3",
      name: "Binance",
      symbol: "BNB",
      image:binance,
      percentage:"15",
      percentage_value:"0.69",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"4",
      name: "Litecoin",
      symbol: "LTC",
      image:litecoin,
      percentage:"10",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"5",
      name: "XRP",
      symbol: "XRP",
      image:xrp,
      percentage:"7",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"6",
      name: "Dogecoin",
      symbol: "DOGE",
      image:dogecoin,
      percentage:"6",
      percentage_value:"0.357",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"7",
      name: "Solana",
      symbol: "SOL",
      image:solana,
      percentage:"5",
      percentage_value:"0.689",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"8",
      name: "Cardano",
      symbol: "ADA",
      image:cardano,
      percentage:"4",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"9",
      name: "Polkadot",
      symbol: "DOT",
      image:polkadot,
      percentage:"4",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"10",
      name: "Polygon",
      symbol: "MATIC",
      image:Polygon,
      percentage:"4",
      percentage_value:"0.357",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    ],
  },
  {
    id: "3",
    name: "AlphaCrypto ETF",
    symbol: "ALCRYP",
    value: "480",
    shop: "",
    logo: altcry,
    small_logo: small_altcry,
    oneY:2.5,
    threeY:2.5,
    fiveY:2.5,
    incept:5,
    incept_date:"10-Jan-2021",
    perf_date:"31-Oct-2023",
    desc:'A "Non-Indexx Crypto Heavy ETF" is an exchange-traded fund that primarily comprises digital tokens representing a diverse range of cryptocurrencies, excluding those tracked by a specific index like Bitcoin or Ethereum. This ETF offers exposure to a broader selection of digital assets beyond the well-known ones, potentially providing more diversified exposure to the cryptocurrency market.',
    column_name:"Tokens",
    components:[
      {
      id:"1",
      name: "Bitcoin",
      symbol: "BTC",
      image:bitcoin,
      percentage:"25",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"2",
      name: "Ethereum",
      symbol: "ETH",
      image:eth,
      percentage:"20",
      percentage_value:"0.84",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"3",
      name: "Binance",
      symbol: "BNB",
      image:binance,
      percentage:"15",
      percentage_value:"0.69",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"4",
      name: "Litecoin",
      symbol: "LTC",
      image:litecoin,
      percentage:"10",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"5",
      name: "XRP",
      symbol: "XRP",
      image:xrp,
      percentage:"7",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"6",
      name: "Cardano",
      symbol: "ADA",
      image:cardano,
      percentage:"6",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"7",
      name: "Polkadot",
      symbol: "DOT",
      image:polkadot,
      percentage:"5",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"8",
      name: "Polygon",
      symbol: "MATIC",
      image:Polygon,
      percentage:"4",
      percentage_value:"0.357",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"9",
      name: "Solana",
      symbol: "SOL",
      image:solana,
      percentage:"4",
      percentage_value:"0.689",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"10",
      name: "Chainlink",
      symbol: "LINK",
      image:chainklink,
      percentage:"4",
      percentage_value:"0.689",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"11",
      name: "UNUS SED LEO",
      symbol: "LEO",
      image:UNUS,
      percentage:"4",
      percentage_value:"0.689",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"12",
      name: "Dogecoin",
      symbol: "DOGE",
      image:dogecoin,
      percentage:"4",
      percentage_value:"0.357",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },


    ],
  },
  {
    id: "4",
    name: "Indexx Focus ETF",
    symbol: "INDXXF",
    value: "128.2",
    shop: "",
    logo: indxxf,
    small_logo: small_indxxf,
    oneY:2.5,
    threeY:2.5,
    fiveY:2.5,
    incept:5,
    incept_date:"10-Jan-2021",
    perf_date:"31-Oct-2023",
    desc:'The "Indexx Focus ETF" is an exchange-traded fund focused on digital tokens that represent ownership in a particular stock market index. Instead of holding actual stocks, it primarily comprises these tokens, which are often blockchain-based. This ETF offers a convenient and decentralized way to gain exposure to the performance of the underlying index. 70% Indexx Tokens, 30% Other Main Tokens',
    column_name:"Stock Token/Tokens",
    components:[
      {
        id:"1",
        name: "Apple",
        symbol: "AAPL",
        image:apple_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"2",
        name: "Meta",
        symbol: "META",
        image:meta_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"3",
        name: "Tesla",
        symbol: "TSLA",
        image:tesla_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"4",
        name: "Indexx S&P 500",
        symbol: "S&P500",
        image:apple_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"5",
        name: "Indexx Exchange",
        symbol: "INEX",
        image:INEX,
        percentage:"14",
        percentage_value:"0.33",
        buy:"",
        type:"Token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"6",
        name: "Indexx 500",
        symbol: "IN500",
        image:IN500,
        percentage:"14",
        percentage_value:"0.33",
        buy:"",
        type:"Token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"7",
        name: "Indexx Crypto",
        symbol: "INXC",
        image:INXC,
        percentage:"14",
        percentage_value:"0.33",
        buy:"",
        type:"Token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"8",
        name: "IUSD+",
        symbol: "IUSD+",
        image:iUSD,
        percentage:"14",
        percentage_value:"0.33",
        buy:"",
        type:"Token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
      id:"9",
      name: "Bitcoin",
      symbol: "BTC",
      image:bitcoin,
      percentage:"5",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"10",
      name: "Ethereum",
      symbol: "ETH",
      image:eth,
      percentage:"5",
      percentage_value:"0.84",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"11",
      name: "Binance",
      symbol: "BNB",
      image:binance,
      percentage:"5",
      percentage_value:"0.69",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"12",
      name: "Litecoin",
      symbol: "LTC",
      image:litecoin,
      percentage:"5",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"13",
      name: "XRP",
      symbol: "XRP",
      image:xrp,
      percentage:"3",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"14",
      name: "Cardano",
      symbol: "ADA",
      image:cardano,
      percentage:"3",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"15",
      name: "Polkadot",
      symbol: "DOT",
      image:polkadot,
      percentage:"2",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"16",
      name: "Solana",
      symbol: "SOL",
      image:solana,
      percentage:"2",
      percentage_value:"0.689",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"17",
      name: "USDT",
      symbol: "USDT",
      image:usdt,
      percentage:"1",
      percentage_value:"0.357",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    ],
  },
  {
    id: "5",
    name: "Token Blend ETF",
    symbol: "TOB",
    value: "500.9",
    shop: "",
    logo: bltok,
    small_logo: small_bltok,
    oneY:2.5,
    threeY:2.5,
    fiveY:2.5,
    incept:5,
    incept_date:"10-Jan-2021",
    perf_date:"31-Oct-2023",
    desc:'The "Token Blend ETF" is an exchange-traded fund designed to provide investors with a combination of exposure to a particular index, typically composed of stocks, and other digital tokens representing various assets. This ETF allocates 50% of its holdings to the specified index and the remaining 50% to a diverse range of other tokenized assets, offering a balanced investment approach.',
    column_name:"Stock Token/Tokens",
    components:[
      {
        id:"1",
        name: "Meta",
        symbol: "META",
        image:meta_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"2",
        name: "Amazon",
        symbol: "AMZN",
        image:amzn_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
        id:"3",
        name: "BroadCom",
        symbol: "BCM",
        image:broadcom_token,
        percentage:"10",
        percentage_value:"0.689",
        buy:"",
        type:"Stock token",
        sector:"Cash and/or Derivatives",
        asset_class:"Cryptocurrency",
        market_value:215.49,
        shares:"5467898",
        market_currency:"USD"
      },
      {
      id:"4",
      name: "Bitcoin",
      symbol: "BTC",
      image:bitcoin,
      percentage:"10",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"5",
      name: "Ethereum",
      symbol: "ETH",
      image:eth,
      percentage:"10",
      percentage_value:"0.84",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"6",
      name: "Indexx Exchange",
      symbol: "INEX",
      image:INEX,
      percentage:"15",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"7",
      name: "Indexx 500",
      symbol: "IN500",
      image:IN500,
      percentage:"15",
      percentage_value:"0.33",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"8",
      name: "Toncoin",
      symbol: "TONCOIN",
      image:Toncoin,
      percentage:"10",
      percentage_value:"0.69",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"9",
      name: "Dai",
      symbol: "DAI",
      image:dai,
      percentage:"5",
      percentage_value:"0.243",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    {
      id:"10",
      name: "SHIBA INU",
      symbol: "SHIB",
      image:shiba,
      percentage:"5",
      percentage_value:"0.763",
      buy:"",
      type:"Token",
      sector:"Cash and/or Derivatives",
      asset_class:"Cryptocurrency",
      market_value:215.49,
      shares:"5467898",
      market_currency:"USD"
    },
    ],
  },

];
