import React, { useEffect, useRef, useState } from "react";
import CrossIcon from "../../assets/headerTop/cross";
import Logo from "../../assets/headerTop/indexx_logo";
import "./styles.css";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "react-responsive";

import DarkMode from "../DarkMode/DarkMode";
import Data from "./data";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const poppins = {};

const HeaderTest = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const themes = useTheme();
  const elementRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [headerData, setHeaderData] = useState(Data);
  const [backdropVisibility, setBackdropVisibility] = useState(false);
  //
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  //
  useEffect(() => {
    console.log("theme_value", theme);
  }, [theme]);
  //
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const email = localStorage.getItem("email"); // Assuming you also store email
    setIsLoggedIn(!!accessToken && !!refreshToken && !!email);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const checkForToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const signInToken = urlParams.get("signInToken");

      if (signInToken) {
        // Decode the JWT token payload
        const decodedPayload = decodeToken(signInToken);
        console.log("Decoded Payload:", decodedPayload);

        // Make an API request with the email from the decoded token
        const baseUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/api"
            : "https://indexx.ai/api";

        try {
          const response = await axios.get(`${baseUrl}/loginWithToken`, {
            params: { email: decodedPayload.email },
          });

          // Store the tokens and user data in localStorage
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("email", decodedPayload.email);
          localStorage.setItem("userType", decodedPayload.userType);

          // Redirect to home page
          navigate("/");
          window.location.reload();
        } catch (error) {
          console.error("Error logging in with token:", error);
        }
      }
    };

    checkForToken();
  }, [navigate]);

  // Function to decode the JWT token
  const decodeToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  // const handleLogin = (isLogin) => {
  //   if (!isLogin) return;

  //   setModalShow(true);
  // };
  // const handleLogout = (isLogout) => {
  //   if (!isLogout) return;
  //   localStorage.removeItem("access_token");
  //   localStorage.removeItem("refresh_token");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("userType");
  //   setIsAuthenticated(false);
  //   setIsLoggedIn(false);
  // };
  // const handleItemClick = (path, i) => {
  //   setactiveIndex(i);
  //   window.location.href = path;
  // };
  const updateBackDropVisibility = (type) => {
    if (type === "enter") setBackdropVisibility(true);
    if (type === "leave") setBackdropVisibility(false);
  };

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const visibility = computedStyle.getPropertyValue("visibility");
      const display = computedStyle.getPropertyValue("display");

      if (visibility === "hidden" || display === "none") {
        console.log("Element is hidden or not visible");
      } else {
        console.log("Element is visible");
        setBackdropVisibility(true);
      }
    }
  }, []);
  return (
    <>
      <nav
        style={{ position: "fixed", top: 0, left: 0, zIndex: 10000 }}
        className={poppins.className}
      >
        <div className="wrapper">
          {!isMob ? (
            <div
              className="backdrop"
              style={{
                display: backdropVisibility ? "block" : "none",
                background: theme === "dark" ? "rgba(0,0,0,0.5)" : "",
              }}
            ></div>
          ) : (
            ""
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",

              flex: 1,
            }}
          >
            <div className="logo" style={{ marginRight: "30px" }}>
              <a href="https://indexx.ai" style={{ display: "flex" }}>
                <Logo />
              </a>
            </div>
            <input type="radio" name="slider" id="menu-btn" />
            <input type="radio" name="slider" id="close-btn" />
            <ul className="nav-links" style={{ flex: 1 }}>
              <label htmlFor="close-btn" className="btn close-btn">
                x
              </label>
              {headerData.map((element) => (
                <>
                  <li
                    className="main"
                    onMouseEnter={() => updateBackDropVisibility("enter")}
                    onMouseLeave={() => updateBackDropVisibility("leave")}
                  >
                    <a
                      href={element.href}
                      className={`desktop-item ${
                        element.active ? "link_active" : ""
                      } ${poppins.className}`}
                      onMouseEnter={() =>
                        updateBackDropVisibility(
                          "enter",
                          element.hasMegaDrop ? true : false
                        )
                      }
                    >
                      {element.mainTextDesktop}
                    </a>
                    <input type="checkbox" id={element.mainTextDesktop} />
                    <label
                      htmlFor={element.mainTextDesktop}
                      className={`mobile-item ${poppins.className}`}
                    >
                      {element.mainTextMob} {element.hasMegaDrop ? ">" : ""}
                    </label>

                    {element.hasMegaDrop ? (
                      <div
                        className="mega-box"
                        ref={elementRef}
                        style={{
                          background: theme === "light" ? "#FAFAFC" : "",
                          color: theme === "light" ? "#333336" : "white",
                          paddingLeft: "65px",
                        }}
                      >
                        <div className="content">
                          {element.dropDownContent.map((elem, i) => (
                            <div
                              key={i}
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <header
                                style={{
                                  color: theme === "light" ? "#A0A0A5" : "",
                                  textAlign: "left",
                                }}
                                className={`${poppins.className}`}
                              >
                                {elem.heading}
                              </header>
                              <ul
                                className={`mega-links ${
                                  elem.mainList ? "main" : ""
                                }`}
                              >
                                {elem.links.map((el, i) => (
                                  <li
                                    key={i}
                                    className="profile-list-item"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "left",
                                    }}
                                  >
                                    <a
                                      href={el.href}
                                      style={{
                                        color:
                                          theme === "light"
                                            ? "#333336"
                                            : "white",
                                        textAlign: "left",
                                      }}
                                      className={` ${poppins.className}`}
                                    >
                                      {el.name}
                                    </a>
                                    <div
                                      className={`profile-inner-item-border-bottom `}
                                      style={{
                                        background: "#11BE6A",
                                        width: "16px",
                                        height: "3px",
                                        marginTop: "-5px",
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}

                          <div className="row"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {element.hasDrop ? (
                      <ul class="drop-menu">
                        <li>
                          <a href="#">Drop menu 1</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 2</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 3</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 4</a>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DarkMode />
            <label htmlFor="menu-btn" className="btn menu-btn">
              <CrossIcon />
            </label>
          </div>
        </div>
      </nav>
      {/* {headerData.find(
        (el) => el.active === true && el.hasMegaDrop === true
      ) ? (
        <div
          className="secondary_header_root"
          style={{ background: theme === "dark" ? "#000" : "" }}
        >
          <div className="secondary_header_content">
            {headerData
              .find((el) => el.active === true)
              ?.dropDownContent.find((elem) => elem.mainList === true)
              ?.links?.map((curr, i) => (
                <div
                  key={i}
                  className={`secondary_header_content_item ${
                    i === activeIndex ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(curr.href, i)}
                >
                  <span className="secondary_header_content_img_container">
                    <img
                      src={theme === "light" ? curr?.imgLight : curr?.imgDark}
                    />
                  </span>
                  <span>{curr.name}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default HeaderTest;
