import React, { useRef } from "react";
import {
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
// import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
//Icons
import Slider from "react-slick";
// import { BiSort } from "react-icons/bi";
// import { MdHistory } from "react-icons/md";
// import { MdTune } from "react-icons/md";

import { useTheme } from "@emotion/react";

// Tab filters
const filterTabs = [
  {
    id: 1,
    text: "All",
    path: "?type=all",
  },
  {
    id: 2,
    text: "High Tier",
    path: "?type=high",
  },
  {
    id: 3,
    text: "Mid Tier",
    path: "?type=mid",
  },
  {
    id: 4,
    text: "Low Tier",
    path: "?type=low",
  },
];

const FilterTab = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const navigate = useNavigate();

  // const openMenu = Boolean(anchorEl);

  // const handleClickTrigger = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          initialSlide: 2.2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const liveSlideRef = useRef();
  const nextButtonLive = () => {
    liveSlideRef.current.slickNext();
  };
  const previousButtonLive = () => {
    liveSlideRef.current.slickPrev();
  };

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{ mb: 8 }}
          style={{ overflow: "hidden", position: "relative" }}
        >
          <Box container alignContent={"center"} xs={12}>
            <Box
              // bgcolor={darkMode ? "#171717" : "#EEEEEE"}
              bgcolor={"var(--background_lighter)"}
              color={"var(--color-text)"}
              sx={{
                textAlign: "center",
                py: 1,
                px: 4,
                borderRadius: "5px",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Slider ref={liveSlideRef} {...sliderSettings}>
                {filterTabs.map((ft, index) => (
                  <Button
                    // color="#ffffff"
                    onClick={() => navigate(ft.path)}
                    style={{ textTransform: "capitalize", color:"var(--color-text)",
                      "&:hover": {
                              background: "#171717",
                          },
                     }}
                    disableTouchRipple
                  >
                    <Typography
                      sx={
                        location.search === ft.path ||
                        (index === 0 && !location.search)
                          ? {
                              // borderBottom: `1px solid ${
                              //   darkMode ? "#ffffff" : "#171717"
                              // }`,
                              borderBottom: "1px solid var(--color-text)",
                              color:"var(--color-text)",
                            }
                          : {
                            color:"var(--color-text)",
                          }
                      }
                      variant="body2"
                      component="span"
                    >
                      {`${ft.text}`}
                    </Typography>
                  </Button>
                ))}
              </Slider>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ my: 1, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              overflowX: "scroll",
              gap: 2,
              py: 2,
            }}
          >
            {filterTabs.map((ft, index) => (
              <Box key={ft.id}>
                <Box>
                  <Box
                    bgcolor={
                      location.search === ft.path||
                        (index === 0 && !location.search)
                        ? {
                            backgroundColor: `#0294FE`,
                          }
                        : {
                            // backgroundColor: `${
                            //   darkMode ? "#171717" : "#EEEEEE"
                            // }`,
                            backgroundColor: "#171717",
                          }
                    }
                    onClick={() => navigate(ft.path)}
                    sx={{
                      py: 1,
                      px: 2.4,
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color:"var(--color-text)" }}
                    >
                      {`${ft.text}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FilterTab;
