import React, { useState, useEffect } from "react";
import wrench from "../../assets/third-header/wrench 2 1.svg";
import question from "../../assets/third-header/question 1.svg";
import vs from "../../assets/third-header/vs 1.svg";
import tag from "../../assets/third-header/tag3 1.svg";

import tag_light from "../../assets/third-header/light-icons/tag3 2.svg";
import wrench_light from "../../assets/third-header/light-icons/wrench 2 1.svg";
import question_light from "../../assets/third-header/light-icons/question 2.svg";
import vs_light from "../../assets/third-header/light-icons/vs 2.svg";

import compare_token from "../../assets/arts/compare_token.svg";
import working_token from "../../assets/arts/working_token.svg";

import compare_token_light from "../../assets/arts/learn-more-light/compare_token.svg";
import working_token_light from "../../assets/arts/learn-more-light/working_token.svg";

import { CollectionData } from "../Collections/CollectionData";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import SubHeader from "../SubHeader/SubHeader";
import "./LearnToken.css";
import Slider from "react-slick";
import TokenFAQ from "./TokenFAQ";
import HowtoBuyToken from "./HowtoBuyToken";


const TabPanel = ({ index, value, children }) => (
    <div hidden={value !== index} style={{ marginTop: '20px' }}>
      {value === index && <div>{children}</div>}
    </div>
  );

const LearnToken = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.scrollTo(0,0);
  };

  const [theme, setTheme] = useState(
    localStorage.getItem('selectedTheme') || 'light'
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleStorageChange = (event) => {
      console.log(event);
      setTheme(event.currentTarget.localStorage.selectedTheme);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, 
    centerMode: true,
    centerPadding: '25%',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
          initialSlide: 1.2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // console.log(data, "data");


  return (
    <div>
      <SubHeader />

      <Box sx={{
        position: "fixed",
        top: "170px",
        width: "100%",
        zIndex: "999",
        background: "var(--background)",
        color:"var(--color-text)",
        height: "100px",
      }}>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example"
          centered
          className="learn-tabs"
        >
          <Tab
            icon={<img src={theme === "dark" ? tag : tag_light} alt="Home" width={"30px"} height={"27px"} />}
            label="How to Buy Stock Token"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? wrench : wrench_light} alt="Home" width={"30px"} height={"27px"} />}
            label="How it works"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? vs : vs_light} alt="Home" width={"31px"} height={"25px"}/>}
            label="Stock Token Comparison"
            disableRipple
          />
          <Tab
            icon={<img src={theme === "dark" ? question : question_light} alt="Home" width={"30px"} />}
            label="FAQ"
            disableRipple
          />
        </Tabs>
      </Box>
      <Box pt={40} pb={3}>

      <TabPanel value={value} index={0}>
        <HowtoBuyToken />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Typography fontSize={"60px"} textAlign="center" mb={5}>
          How Stock Token Works
        </Typography>
                            <Slider  {...sliderSettings}>


                              {CollectionData?.map((token) => (
                                    <Box>

                                    <Box
                                        component={"img"}
                                        src={token.token_photo}
                                        alt='logo'
                                        width={"90%"}
                                        maxHeight={"355px"}
                                    />
                                    </Box>

                              ))}

                            </Slider>
        <Box
          component={"img"}
          src={theme === "dark" ? working_token : working_token_light}
          alt='logo'
          my={10}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography fontSize={"60px"} textAlign="center">
          Indexx Stock Tokens
        </Typography>
        <Typography fontSize={"60px"} textAlign="center" mt={"-30px"}>
                     vs 
        </Typography>
        <Typography fontSize={"60px"} textAlign="center" mt={"-30px"}>
            Traditional Stocks
        </Typography>
        <Box
          component={"img"}
          src={theme === "dark" ? compare_token : compare_token_light}
          alt='logo'
          my={10}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography fontSize={"60px"} textAlign="center" mb={5}>
          Frequently Asked Questions
        </Typography>
        <TokenFAQ/>
      </TabPanel>
      </Box>

    </div>
  );
};

export default LearnToken;
